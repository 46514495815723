.innerwrapper {
    padding-top: 35px;
}
.innerwrapper .results-tab-wrap .nav-tabs {
    justify-content: flex-start;
}
.tooltip > div.tooltip-inner {
    background-color: $primary-color !important;
}
.tooltip.show {
    opacity: 1 !important;
}

.tooltip > div.tooltip-inner::before {
    border-color: $primary-color !important;
    color: $primary-color !important;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $primary-color !important;
}
.tooltip-inner {
    font-size: 12px;
    text-align: center;
}
.action-icon-cell {
    cursor: pointer;
}
.delete-confirm-wrap {
    padding: 10px 0;
}
.innerwrapper .results-tab-wrap .nav-tabs::after {
    left: 0;
    right: 0;
}

.innerwrapper .results-tab-wrap .nav-tabs .nav-link {
    padding: 5px 30px;
}
.savedquery-wrap {
    min-height: calc(100vh - 120px);
}