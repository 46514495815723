.thumb-slider-with-animation {
    display: flex;
    text-align: left;
    align-items: center;

    .mySwiper {
        width: 45%;
        padding-right: 45px;
        margin: auto;

        .swiper-wrapper {
            flex-direction: column;
            height: auto;
            padding-left: 5px;
            padding-top: 25px;

            .swiper-slide {
                width: 100% !important;
                margin: 0 0 25px 0 !important;
                padding: 0 10px 0 10px;

                .thumb-content-area {
                    box-shadow: 0px 5px 10px 0px #9FABB640;
                    cursor: pointer;
                    padding: 25px 15px;
                    border-radius: 7px;
                    display: flex;
                    position: relative;
                    overflow: hidden;

                    .thumb-line {
                        width: 4px;
                        background-color: #DEE3EA;
                        display: block;
                        margin-right: 24px;
                        position: relative;

                        &::after {
                            content: "";
                            width: 4px;
                            height: 0;
                            position: absolute;
                            left: 0;
                            background-color: #1A73E8;
                        }
                    }

                    .thumb-cont {
                        flex: 1
                    }

                    label {
                        color: #5B5B5B;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0.75px;
                        margin: 0 0 16px;
                    }

                    p {
                        color: #5B5B5B;
                        font-weight: 300;
                        line-height: 20px;
                        letter-spacing: 0.65px;
                    }
                }

                &.swiper-slide-thumb-active {
                    .thumb-line::after {
                        height: 100%;
                        transition: all 6s ease;
                    }

                    label {
                        color: #0338C0;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }

                    .thumb-content-area {
                        box-shadow: 0px 10px 20px 0px rgba(85, 98, 111, 0.25);
                    }
                }
            }
        }
    }

    .mySwiper2 {
        width: 55%;
        border-radius: 20px;
        background: #D5E4F9;

        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-slide {
                position: relative;
                left: -105%;
            }

            .right-slide {
                position: relative;
                right: -105%;
            }

            .top-slide {
                position: relative;
                top: -270px;
            }
        }

        .swiper-slide.swiper-slide-active .top-slide {
            top: 0;
            transition: all 1.5s ease;
        }

        .swiper-slide.swiper-slide-active .left-slide {
            left: 0;
            transition: all 1.5s ease;
        }

        .swiper-slide.swiper-slide-active .right-slide {
            right: 0;
            transition: all 1.5s ease;
        }
    }

    .chat-slide-left-img {
        border-radius: 10px;
        box-shadow: 0px 20px 30px 0px #A5C2EB;
    }

    .chat-slide-typer-area {
        border-radius: 7px;
        background: #FFF;
        box-shadow: 0px 20px 30px 0px #A5C2EB;
        padding: 15px;
        min-height: 200px;

        .Typewriter {
            padding: 0 15px;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .chat-slide-right-img1 {
        box-shadow: 0px 20px 30px 0px #A5C2EB;
        border-radius: 7px;
    }

    .chat-slide-right-img2 {
        box-shadow: 0px 10px 10px 0px rgb(182 211 239 / 45%);
        max-width: 350px;
        width: 100%;
    }

    .chat-slide-right-img3 {
        // box-shadow:0px 20px 30px 0px #A5C2EB;
        border-radius: 7px;
        margin-top: 32px;
    }

    .search-slide-top-img {
        box-shadow: 0px 10px 10px 0px #A3C0E8;
        border-radius: 11px;
        margin-bottom: 20px;
    }

    .search-slide-left-img {
        box-shadow: 0px 20px 30px 0px #A5C2EB;
        border-radius: 7px;
    }

    .search-slide-typer-area {
        border-radius: 7px;
        background: #FFF;
        box-shadow: 0px 20px 30px 0px #A5C2EB;
        height: 100%;

        .Typewriter {
            padding: 15px 0;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .general-slide-left-area {
        border-radius: 10px;
        box-shadow: 0px 20px 30px 0px #A5C2EB;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        height: 100%;
        img {
            width: 100%;
        }
    }
}


@media (max-width:1199px) {
    .thumb-slider-with-animation .chat-slide-typer-area {
        min-height: auto;
    }
}

@media (max-width:991px) {
    .thumb-slider-with-animation {
        flex-direction: column;

        .mySwiper {
            width: 100%;

            .swiper-wrapper {
                flex-direction: row;

                .swiper-slide {
                    width: 33.3% !important;

                    .thumb-content-area p {
                        display: none;
                    }
                }
            }

        }
    }

    .thumb-slider-with-animation .mySwiper2 {
        width: 100%;
    }

    .thumb-slider-with-animation .mySwiper {
        padding: 0;

        .swiper-wrapper {
            padding: 0;

            .swiper-slide .thumb-content-area {
                flex-direction: column;
                padding: 10px;
                text-align: center;

                label {
                    margin: 0;
                }

                .thumb-line {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    top: 0;
                    left: 0;

                    &::after {
                        width: 0;
                        height: 4px;
                    }
                }
            }

            .swiper-slide.swiper-slide-thumb-active .thumb-line::after {
                width: 100%;
            }
        }
    }
}

.landing-animation-section {
    .container {
        max-width: 1179px;
    }

    .chat-slide-left-img {
        width: 100%;
    }
}


@media (max-width:767px) {
    .thumb-slider-with-animation .chat-slide-typer-area .Typewriter {
        font-size: 10px;
    }
}

@media (max-width:575px) {
    .thumb-slider-with-animation .mySwiper2 .swiper-slide {
        padding: 20px 10px;
    }

    .thumb-slider-with-animation .chat-slide-typer-area .Typewriter {
        padding: 0;
        font-size: 8px;
        line-height: 14px;
    }

    .thumb-slider-with-animation .mySwiper .swiper-wrapper .swiper-slide .thumb-content-area label {
        font-size: 12px;
    }

    .thumb-slider-with-animation .mySwiper .swiper-wrapper .swiper-slide {
        padding: 0 5px;
    }

    .thumb-slider-with-animation .general-slide-left-area {
        border-radius: 7px;
    }

    .thumb-slider-with-animation .search-slide-top-img {
        margin-bottom: 15px;
    }
}