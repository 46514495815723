.land-video-box {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;
}

.land-video-box::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.land-video-box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;
}

.video-frame-section {
    padding: 71px 0 79px;
    background-color: #DDEAFC;
}

.land-videoframe-header-box {
    max-width: 687px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    .landing-common-title {
        max-width: 100%;
    }
}

@media (min-aspect-ratio: 16/9) {
    .land-video-box {
        max-height: 600px;
        height: 100vw;
        max-width: calc(600px * 16 / 9);
    }

    .land-video-box::before {
        padding-top: 0;
    }
}