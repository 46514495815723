.client-slider-section .owl-carousel .owl-item img {
    width: auto;
    max-height: 100%;
    max-width: 100%;
}
.client-logo-wrap {
    padding: 0 15px;
}
.client-logo-wrap {
    padding: 0 15px;
    height: 100px;
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    justify-content: center;
}
.client-logo-text {
    font-weight: bold;
    color: #A4A4A4;
}
.owl-theme .owl-nav .disabled {
    display: none;
}
@media (max-width: 767px) {
.landing-section-8 .landing-common-title {
    margin-bottom: 10px;
}
.landing-section-8 .owl-nav .land-owl-next, .landing-section-8  .owl-nav .land-owl-prev {
    bottom: -20px !important;
}
}