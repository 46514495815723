.change-password-wrap {
    margin-top: 38px;
}
.change-password-wrap .section-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #000;
}
.change-password-wrap .section-title a {
    color: #000;
    text-decoration: none;
}
.changepassword-contents {
    // margin-top: 30px;
    padding: 0 36px;
}

.change-psw-img {
    padding-left: 50px;
}
.change-password-wrap .section-title span {
    margin-right: 18px;
    position: relative;
    top: -1px;
}
.changepassword-contents form {
    max-width: 80%;
}
.change-password-wrap .whitebox.p-30 {
    padding-bottom: 70px;

}
@media(min-width:991px){
    .change-psw-img {min-height: 320px;}
    .change-password-wrap .change-psw-img {
        margin-bottom: 0;
    }
   
    .change-psw-img img {
        max-width: 100%;
    }
}
@media(max-width:1024px){
    .change-psw-img {
        padding-left: 0;
    }
    .change-psw-img img {
        max-width: 100%;
    }
}
@media(max-width:992px){ 
    .changepassword-contents form {
        max-width: 100%;
    }   
}
@media(max-width:767px){
    .change-psw-img {
        display: none;
    }
    .changepassword-contents {
        padding: 0;
        margin-top: 30px;

    }
    .change-password-wrap .whitebox.p-30 {
        padding-bottom: 40px;
    
    }
 }