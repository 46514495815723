body {
    font-family: $primary-font;
    font-size: $body-font-size;
    color: $theme-body-font-color;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body .btn-primary {
    min-width: 125px;
    padding: 0 20px;
    height: 38px;
    border-radius: $radius;
    border: none;
    color: #fff;
    font-weight: normal;
    font-size: $body-font-size;
}
.form-control.input-item {
    background-color: #ffffff;
    border-radius: 8px;
    height: 39px;
    border: none;
    font-size: $body-font-size-medium;
}
.form-control.textarea-item {
    background-color: #fff;
    border-radius: 8px;
    border: none;
    font-size: $body-font-size-medium;
}
.form-select.select-item {
    background-color: #fff;
    border-radius: 8px;
    height: 39px;
    border: none;
    font-size: $body-font-size-medium;
}
.react-multi-select .css-1s2u09g-control {
    background-color: #fff;
    border-radius: 8px;
    min-height: 39px;
    border: none;
    font-size: $body-font-size-medium;
}

.form-input-wrap .form-label {
    font-weight: 600;
    line-height: 15.5px;
    margin-bottom: 10px;
}
.form-input-wrap {
    margin-bottom: 15px;
}
.form-control.input-item:disabled {
    color: #000;
}
body .btn {
    border-radius: 7px;
    min-width: 110px;
    padding: 0 20px;
    font-size: 13px;
    height: 39px;
    &.btn-sm {
        border-radius: 5px;   
    }
    
}
body .btn-sm {
    height: 30px;
}
.link-btn {
   line-height: 39px; 
}
.link-btn-sm {
    line-height: 30px; 
 }
.white-border-btn {
    color: #fff;
}
.white-border-btn:hover{
    color: #fff;
}
.border-btn {
    border: 1px solid #fff;
}
.border-btn:hover {
    border: 1px solid #fff; 
}
.primary-text {
color: $primary-color !important;
font-weight: 600;
}
.grey-border-btn {
    border: 1px solid #cccdce;
    color: #000;
}
.grey-border-btn.btn:disabled {
    border: 1px solid #cccdce;
    opacity: 0.5;
}
.btn svg {
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.grey-border-btn {
    border: 1px solid #d7e0e9;
}
.grey-border-btn:hover {
    border: 1px solid #d7e0e9; 
    color: #000;
}
.white-btn {
    background: #fff;
    color: #000;
}
.white-btn:hover {background: #fff;
    color: #000;}
.double-btn .btn {
    margin-left: 13px;
}
.double-btn .btn:first-child {
    margin-left: 0;
}
.form-control.input-item:focus {
    box-shadow: none;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
    box-shadow: none;
}
label.btn {
    height: 39px;
    line-height: 39px;
}
.p-relative {
    position: relative;
}
.p-l-0 {
    padding-left: 0 !important;
}
.p-r-0 {
    padding-right: 0 !important;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-b-25 {
    margin-bottom: 25px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.m-b-40 {
    margin-bottom: 40px;
}
.m-b-50 {
    margin-bottom: 50px;
}
.m-b-60 {
    margin-bottom: 60px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-t-15 {
    margin-top: 15px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-t-30 {
    margin-top: 30px;
}
.m-t-40 {
    margin-top: 40px;
}
.m-t-50 {
    margin-top: 50px;
}
.p-15 {
    padding: 15px;
}
.p-20 {
    padding: 20px;
}
.p-25 {
    padding: 25px;
}
.p-30 {
    padding: 30px;
}
.primary-color {
    color: $primary-color;
}
.primary-bg {
    background: $primary-color;
}
.btn-primary img {
    margin-right: 5px;
}
.sec-sub-head {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
}
.right-border {
    border-right: 1px solid #d9d9d9;
}
.left-border {
    border-left: 1px solid #d9d9d9;
}
.top-border {
    border-top: 1px solid #d9d9d9;
}
.bottom-border {
    border-bottom: 1px solid #d9d9d9;
}
.m-b-25 {
    margin-bottom: 25px;
}
.whitebox {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    border-radius: $curveradius;
}
.padding-box {
    padding: 15px;
}

body .btn-check:focus + .btn, body .btn:focus {
    outline: 0;
    box-shadow:none;
}
body .btn:hover {
    opacity: 0.8;
}
.text-left {
    text-align: left;
}
.loader-middle-align {
    display: flex;
    min-height: 20vh;
    align-items: center;
    justify-content: center;
}
.common-modal.header-no-text .modal-header {
    padding: 0 0 7px;
    border: none;
    padding: 0;
}
.results-tab-wrap .tab-content-wrap  {
    min-height: calc(100vh - 120px);
}
.scrollarea-content {
    padding-right: 10px;
}
body .tooltip > div.tooltip-inner {
    background-color: #d2d5db !important;
    color: #333;
}
.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #d2d5db !important;
}
body .bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #d2d5db !important;
}
body .tippy-tooltip {
    font-size: 12px;
}
.no-fixed-height {
    height: auto;
}
.p-10 {
    padding: 10px;
}
.column-title-link[href]:hover, .column-title:hover {
    color: $primary-color !important;
}
.file-uploaded-text {
    word-break: break-all;
}
// @media (min-width:1200px){
//     body .container {
//         max-width: 1140px;
//     }
// }
.full-width-container {
    max-width: 100% !important;
    padding: 0 15px;
}
@media (max-width:767px){
    .container {
        padding-left: 15px;padding-right: 15px;
    }
    .tooltip-arrow {
        display: none !important;
    }
    .bs-tooltip-top {
        display: none !important;
    }
}























