.faq-header {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 24px;
    margin-bottom: 20px;
}
.faq-header h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.02em;
    margin-bottom: 0;
}
.faq-header-right {
    flex: 1;
    padding-left: 45px;
}
.faq-footer-sec h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.02em;
}
.faq-footer-sec p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #5b5b5b;
}
.faq-footer-sec textarea.textarea-item {
    background: #f3f5f7;
    border: none;
    min-height: 114px;
    font-size: 13px;
    padding: 15px;
    max-height: 114px;
}
.faq-footer-sec textarea.textarea-item::placeholder {
    font-style: italic;
}
.faq-footer-sec .form-control:focus {
    background-color: #f3f5f7;
    border-color: inherit;
    outline: 0;
    box-shadow: none;
}
@media (max-width: 767px) {
    .faq-header .d-flex {
        flex-wrap: wrap;
    }
    .help-and-support-wrap .p-30{
        padding: 0;
        box-shadow: none;
    }
    .faq-header h4 {
        width: 100%;
    }
    .faq-header-right {
        flex: 1;
        padding-left: 0;
        margin-top: 15px;
        width: 100%;
    }
    .faq-header {
        margin-bottom: 0;
    }
    .faq-header h4, .faq-footer-sec h3 {
        font-size: 20px;
        line-height: 24px;
    }

}
