.technology-cont-icon {
    margin-right: 28px;
}
.technology-show-box-left,
.technology-show-box-right {
    margin-top: 30px;
    margin-bottom: 30px;
}
.technology-cont-text h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}
.technology-show-box-right {
    padding-left: 50px;
}
.technology-show-box-left {
    padding-right: 20px;
}
.landing-section-5 .landing-common-description {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.technology-show-row {
    margin-top: 40px;
}
@media (max-width: 991px) {
    .technology-show-row .technology-show-box {
        align-items: flex-start !important;
    }
}
@media (max-width: 767px) {
    .landing-section-5 .landing-common-description {
        max-width: 100%;
    }
    .technology-show-box-left {
        padding-right: 15px;
        padding-left: 15px;
    }
    .technology-show-box-right {
        padding-left: 15px;
    }
    .technology-cont-text {
        flex: 1;
    }
    .technology-cont-icon {
        margin-right: 0;
        width: 80px;
        padding-top: 3px;
        padding-right: 10px;
    }
    .technology-cont-icon img {
        max-width: 100%;
    }

    .technology-show-row {
        margin-top: 20px;
    }

    .technology-cont-text h4 {
        font-size: 16px;
    }
    .technology-show-box-left,
    .technology-show-box-right {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
