.news-result-contents .sourcebox h3 {
    border: none;
}
.news-result-contents .sourcebox p {
    margin-bottom: 20px;
}
.news-img-sec img {
    width: auto;
    border-radius: $radius-md;
    max-width: 100px;
    margin-right: 12px;
    border: 1px solid #f0f0f0;
}
.new-small-thumb {
    display: flex;
    margin-bottom: 10px;
    min-height: 100px;
}
.news-snippet:empty {
    display: none;
}
.new-small-thumb .sourcebox h3 {
    margin: 0;
    padding: 0;
}
.new-big-sec {
    border-top: 1px solid #d7e0e9;
    padding-top: 25px;
    margin-bottom: 25px;
}
.news-repeat-indetail:nth-child(1) .new-big-sec,
.news-repeat-indetail:nth-child(2) .new-big-sec,
.news-repeat-indetail:nth-child(3) .new-big-sec {
    border: none;
    padding-top: 0;
}
.news-repeat-indetail {
    padding: 0 18px;
}

.upgrade-pop-wrap {
    text-align: center;
    padding: 5px 0;
}
.upgrade-pop-wrap .upgrade-title {
    margin: 19px 0 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
}
.upgrade-pop-wrap p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
}
.news-repeat-indetail .source-content {
    min-height: 64px;
}
.date-text {
    line-height: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #8A8B8C;
    padding-top: 4px;
}
.sourcebox.news-auther-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.authors-name {
//   font-style: italic;
    font-weight: 500;
    color: #000;
    font-size: 12px;
    margin-top: 5px;
}
.authors-name span {
    color: #666;
    margin-right: 5px;
    font-size: 12px;
}
@media (max-width: 991px) {
    .news-repeat-indetail:nth-child(3) .new-big-sec {
        border-top: 1px solid #d7e0e9;
        padding-top: 25px;
    }
}
@media (max-width: 767px) {
    .news-repeat-indetail:nth-child(2) .new-big-sec {
        border-top: 1px solid #d7e0e9;
        padding-top: 25px;
    }
    .new-small-thumb {
        min-height: inherit;
    }
}
