.landing-header {
    padding: 20px;
}
.user-img-landing img {
    max-height: 30px;
}
.landing-header .dropdown {
    margin-left: 10px;
}
.landing-menu-items .dropdown .btn {
    min-width: inherit;
    padding: 0;
    background: transparent;
    border: none;
    color: #5b5b5b;
}
.landing-menu-items .dropdown .dropdown-toggle:focus {
    box-shadow: none;
}
.landing-menu-items .dropdown a {
    margin: 0;
}
.landing-menu-items .dropdown-menu.show.dropdown-menu-end {
    border: none;
    box-shadow: 0 1px 4px #c1c1c1;
    padding: 10px;
}
.landing-menu-items .dropdown-item {
    font-size: 13px;
    line-height: 24px;
}
.landing-menu-items .dropdown a {
    font-size: 13px;
    line-height: 24px;
}
.landing-menu-items .dropdown-item:focus,
.landing-menu-items .dropdown-item:hover {
    color: $primary-color;
    background-color: transparent;
    text-shadow: 0px 0px #1a73e8;
}
.landing-menu-items .dropdown-menu {
    min-width: 120px;
}
.landing-header .user-img img {
    height: 30px;
    width: 30px;
    border: none;
}
.landing-menu-items .dropdown a {
    padding: 0 5px;
    color: #5b5b5b;
} 
@media (min-width: 768px) {
    .btn-primary.m-r-15 {margin-right: 15px;}
}

@media (max-width: 767px) {
    .landing-header .mobilemenu-active .double-btn {
        flex-wrap: wrap;
        padding: 15px;

    }
    .landing-header {
        padding: 10px 0;
    }
    .landing-header .double-btn .btn {
        width: 48%;
        margin: 0;
        flex: 1;
        max-width: 48%;

    }
    .landing-header .double-btn .dropdown  .btn {
        width: 100%;
        max-width:99%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;

    }
    .landing-header .double-btn .dropdown  {
        max-width: 60%;
    }
    .landing-header .mobilemenu-active {
        top: 50px;
        right: 0;
        left: 0;
    }
    .landing-header .dropdown {
        margin-left: 0;
        width: auto;
        margin:0;
        flex: 1;
        border: none;
        padding: 0;

    }
    .landing-header .double-btn .btn:first-child {
        margin-right: 4%;
    }
    .landing-menu-items .dropdown-menu {
        min-width: 120px;
        position: inherit !important;
        transform: none !important;
    }
    .landing-menu-items .dropdown-menu {
        position: inherit !important;
        transform: none !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        padding-top: 10px !important;
    }
    .landing-menu-items .mobilemenu-active .dropdown {
        padding-left: 0;
    }
    .landing-menu-items .dropdown a {
      padding-left: 0;
    }
}
