.landing-section-ai{padding:90px 0; background-color:#DDEAFC;
    .landing-common-title{margin-left:0; max-width:100%;}
}
.icon-cont-box{border-radius:10px; background:#FFF; border-top:7px solid transparent; min-height:150px; height:100%; box-shadow:0px 20px 20px 0px rgba(35, 66, 112, 0.24); padding:25px; display:flex; align-items:center;
    .img-area{margin:0 24px 0 0;
    }
    &:hover{border-top:7px solid #1A73E8;}
}


@media (max-width:767px){
    .landing-section-ai .landing-common-title{text-align:center;}
    .landing-section-ai{padding:30px 0;}
}

@media (max-width:575px){
    .icon-cont-box{min-height:100%;}
}