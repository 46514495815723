.dropzone-modal {
    .modal-lg {
        --bs-modal-width: 649px;
    }

    .modal-header {
        border: none;
    }
}

.dropzone-box {
    background: #F3F5F7;
    border: 2px dashed #1A73E8;
    border-radius: 10px;
    padding: 52px;
    text-align: center;
    margin-bottom: 40px;

    h3 {
        font-size: 17px;
        font-weight: 600;

        span {
            color: #1A73E8;
            text-decoration: underline;
            margin-bottom: 5px;
        }
    }

    p {
        font-size: 12px;
        color: rgba(103, 103, 103, 1);
        margin-bottom: 0;
        line-height: 18px;
        margin-top: 5px;
    }

}

.dpzone-title {
    text-align: center;
    padding: 5px 0 15px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}

.uploaded-item-name-box {
    background: #FFFFFF;
    border: 1px solid #11AF22;
    border-radius: 4px;
    padding: 9px 11px;
    margin-bottom: 10px;

    .uploaded-item-nameshow {
        flex: 1;
        padding-left: 9px;
        padding-right: 9px;
        font-size: 12px;
        color: rgba(15, 15, 15, 1);
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .uploaded-item-delete {
        background: #FFF3F3;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        img{
            width: 70%;
        }
    }

}

.dpzone-sec-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #676767;
}

.uploaded-item-error {
    border-color: rgba(228, 29, 29, 1);
}

.upload-error-text {
    font-size: 11px;
    font-weight: 500;
    color: rgba(228, 29, 29, 1);
    margin-bottom: 0;
}

.uploading-status-show-outer {
    .uploaded-item-name-box {
        margin-bottom: 0;
        border-color: rgba(227, 227, 227, 1);
    }
}

.dpzone-upload-status-sec {
    margin-bottom: 20px;
}

.dpzn-upload-scroll {
    max-height: 130px;
}