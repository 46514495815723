.viewallmembers-wrap {
    position: absolute;
    top: 112px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f3f5f7;
    padding-top: 30px;
}
.viewallmembers-header .row {
    margin: 0 -8px;
}
.viewallmembers-header .row .col {
    padding: 0 8px;
}
.backarrow-wrap h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-left: 15px;
}
.user-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ffffff;
}
.membername-cell h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 0 0 0 15px;
}
.sector-item {
    border: 1px solid #ddd;
    padding: 2px 5px;
    margin: 2px;
    border-radius: 3px;
    font-size: 12px;
}
@media (max-width: 991px) {
    .mebers-select-wrap{
        padding: 0 8px;
        margin-top: 10px;
    }
    .data-table-responsive .rdt_Table {
        width: 900px;
    }
    .user-img img {
        width: 30px;
        height: 30px;
        border: 1px solid #ffffff;
    }
}
@media (max-width: 767px) {
.viewallmembers-wrap .p-30 {
    padding: 15px;
}
.membername-cell h4 {
    font-size: 13px;
    margin: 0;
}
}
