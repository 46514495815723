.home-content-area {
    padding: 23px 0;
}
.home-content-area .sub-head-margin {
    margin-bottom: 30px;
}
.view-similar-btn {
    font-weight: 600;
    font-size: 12px;
    color: #333;
    text-decoration: none;
    border-radius: 30px;
    border: 1px solid #d9d9d9;
    width: 36px;
    white-space: nowrap;
    height: 36px;
    overflow: hidden;
    text-align: center;
    align-items: center;
    padding: 0 7px;
    transition: width 0.5s;
}
.view-similar-btn:hover {
    width: 120px;
    transition: width 0.5s;
}
.view-similar-btn img {
    width: 22px !important;
}
.view-similar-btn span {
    margin-left: 6px;
}

.view-similar-btn span {
    color: #333;
    font-weight: 600;
}
.logo img {
    max-height: 30px;
}
.home-content-area .slider-item .sourcebox p {
    max-height: 60px;
    overflow: hidden;
}
.home-desc-scroll {
    max-height: 110px;
}

.upgrade-pop-wrap.no-results .upgrade-title {
    font-size: 20px;
    line-height: 24px;
}
.upgrade-pop-wrap.no-results .btn-sm {
    min-width: 60px;
}
.homeslider .slick-track {
    margin-left: 0;
}
.reco-date {
    line-height: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #8a8b8c;
    flex-shrink: 0;
    padding-left: 15px;
}
@media(max-width:767px){
    .view-similar-btn {
        width: auto;
    }
    .home-content-area.result-content-area {
        padding: 23px 0 40px; 
    }
}