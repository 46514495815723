.homeslider .slick-prev,
.homeslider .slick-next {
    background: #ffffff;
    border: none;
    box-shadow: 0 1px 4px #c1c1c1;
    border-radius: 4px;
    width: 29px;
    height: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom:0px;
    top: inherit;
    right: 15px;
    transform:none;
}
.homeslider .slick-prev {
    left: inherit;
    right: 55px;
}
.homeslider .slick-prev::before,
.homeslider .slick-next::before{
    display: none;
}
.homeslider .slick-prev.slick-disabled,
.homeslider .slick-next.slick-disabled{
 opacity: 0.5;
 cursor: default;
}
.homeslider {
    padding-bottom: 40px;
}
.hom-secs .multimedia-wrap .homeslider .slick-prev {
    right: 40px;

}
.hom-secs .multimedia-wrap .homeslider .slick-next {
    right: 0px;
}
@media (max-width: 767px) {
.homeslider .slick-next {
    right:0;

}
.homeslider .slick-prev {
    right: 40px;
}
}

// /* the parent */
// .homeslider .slick-list {
//     margin: 0 -10px;
// }

