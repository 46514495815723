.sales-content-section-wrap {
    background-color: #ddeafc;
    padding: 77px 20px 60px;
}
.sales-enquiry-box {
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    padding: 50px 77px;
    border-radius: $radius;
}
.sales-mail-text {
    max-width: 690px;
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;
}
.sales-mail-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}
.sales-mail-text p a {
    color: $primary-color;
}
.sales-content-section-wrap .landing-common-title {
    font-size: 28px;
    max-width: 100%;
}
.sales-enquiry-box .form-control.textarea-item {
    max-height:66px;
}
@media (min-width: 768px) and (max-width: 991px) {
.sales-content-section-wrap .landing-common-title {
    font-size: 20px;
}
}
@media (max-width: 767px) { 
    .sales-enquiry-box {
        padding: 30px 20px;
    }
    .sales-content-section-wrap .landing-common-title {
        font-size: 20px;
    }
    .sales-content-section-wrap {
        background-color: #ddeafc;
        padding: 30px 20px 20px;
    }
    .sales-mail-text {
        padding-top: 30px;
    }
}
