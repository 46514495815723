.auth-page-wrap .container {
    max-width: 1140px;
}
.auth-left {
    width: 584px;
    background-color: #1a73e8;
    // background-image: url(./app/assets/images/signin-left-bg.png);
    height: 612px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 76px;
    position: relative;
    border-radius: $curveradius 0 0 $curveradius;
}
.auth-page-wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #f3f5f7;
    padding: 38px;
}
.auth-container {
    background: #fff;
    border-radius: $curveradius;
}
.auth-right {
    flex: 1;
    padding: 50px 62px;
}
.sec-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 26px;
}
.forgot-pwd-link a {
    color: $theme-body-font-color;
    font-weight: 600;
    text-decoration: none;
}

a.btn-primary {
    text-decoration: none;
}
.auth-logo-wrap {
    position: absolute;
    left: 45px;
    top: 45px;
}
.already-have-link {
    font-size: $body-font-size-medium;
}
.already-have-link a {
    font-weight: 700;
    text-decoration: none;
    font-size: $body-font-size;
}
.auth-logo-wrap img, .signup-logo-wrap img {
    max-height: 30px;
}
@media (max-width: 1200px) {
    .auth-left {
        width: 50%;
    }
}
@media (max-width: 991px) {
    .auth-left {
        height: 500px;
    }
    .auth-right {
        padding: 20px;
    }
    .auth-signin-img {
        text-align: center;
    }
    .auth-signin-img img {
        max-width: 80%;
    }

    .auth-page-wrap {
        display: flex;
        align-items: center;
    }
    .auth-container {
        flex-wrap: wrap;
        border-radius: 20px;
    }
    .auth-right {
        width: 100%;
        flex: auto;
        padding: 20px;
    }
    .auth-signin-img {
        display: none;
    }
    .auth-left {
        width: 100%;
        border-radius: 20px 20px 0 0;
        background-image: none !important;
        background-color: $primary-color;
        height: 60px;
        padding: 0;
    }
    .auth-logo-wrap {
        top: 15px;
        right: 45px;
        text-align: center;
    }
    .auth-logo-wrap img {
        max-height: 30px;
    }
    .auth-page-wrap {
        padding: 20px 10px;
    }
    .sec-title {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
