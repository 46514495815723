.banner-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #ffffff;
}
.about-content-sec {
    padding: 80px 0;
}
.about-content-sec-2 {
    background: #ddeafc;
}
.aboutus-head-wrap {
    padding-left: 50px;
}
.aboutus-title {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    // text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}
.aboutus-desc-wrap p {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
}
.aboutus-desc-wrap p:last-child {
    margin-bottom: 0;
}
.aboutus-desc-wrap {
    padding-right: 10%;
}
@media (min-width: 768px) and (max-width: 991px) {
    .aboutus-title {
        font-size: 20px;
        line-height: 26px;
    }
    .aboutus-head-wrap {
        padding-left: 30px;
    }
    .about-content-sec {
        padding: 40px 0;
    }
}
@media(max-width:767px){
    .aboutus-head-wrap {
        padding-left: 0px;
    }
    .about-content-sec {
        padding: 30px 0 34px;
        text-align: center;
    }
    .aboutus-desc-wrap {
        padding-right: 0;
    }
    .aboutus-title {
        font-size: 20px;
        line-height: 26px;

    }
}
