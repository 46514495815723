.discoversec-content-box-wrap {
    margin: 0 -160px 0 -50px;
}
.discoversec-content-box {
    background: #ffffff;
    box-shadow: 0px 20px 20px rgba(59, 89, 134, 0.25);
    padding: 45px 30px; border-radius:10px;
}
.discover-count-box h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.05em;
}
.discover-count-box h6 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
}

.discover-count-box label {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.05em;
}
.discover-count-box {
    margin-top: 30px;
    margin-bottom: 30px;
}
.discoversec-content-box h3 {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.05em;
    margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1024px) {
    .discoversec-content-box-wrap{
        margin-left: -20px;
    }
    .discoversec-content-box h3 {
        font-size: 20px;
        line-height: 26px;
    }
    
    }
@media (min-width: 768px) and (max-width: 991px) {
.discoversec-content-box-wrap{
    margin-left: 0;
}

}
@media (max-width: 767px) {
    .discoversec-content-box-wrap {
        margin: 0;
    }
    .discoversec-content-box h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
    }
    .discover-count-box h4 {
        font-size: 20px;
        line-height: 24px;
    }
    .discover-count-box h6 {
        font-size: 13px;
    }
    .discoversec-content-box {
        box-shadow: 0px 0px 10px rgb(59 89 134 / 25%);
        padding: 20px 20px;
        text-align: center;
        margin-top: 20px
    }
    .discover-count-box {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .discover-count-box label {
        font-size: 11px;
        line-height: 12px;
    }
    
}
