.multi-result-contents .sourcebox h3 {
    font-size: 14px;
    border-bottom: none;
}
.multi-result-contents .sourcebox {
    margin-bottom: 0;
    height: 100%;
}
.mutimedia-results-repeatbox {
    margin-bottom: 40px;
}
.sorce-footer-button-container {
    display: flex;
    align-items: center;
}