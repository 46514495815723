.notification-dropdown .dropdown-menu {
    width: 390px;
    background-color: #fff;
}
.notification-dropdown .dropdown-menu.show.dropdown-menu-end {
    padding: 10px 0 0;
}
.notification-wrap {
    position: relative;
}
.notification-dropdown .dropdown-menu .notification-wrap::after {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    top: -24px;
    right: 20px;
    content: "";
}
.notification-dropdown .dropdown-menu {
    transform: translate(30px, 61px) !important;
}

.notification-dropdown .dropdown-menu .notification-list {
    padding: 0;
    // margin-right: -10px;
}
.notification-dropdown .dropdown-menu .dropdown-item {
    list-style: none;
    padding: 0 5px;
}
.notification-list .dropdown-item h3 {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #212529;
}
.notification-list h3 .notify-user-name {
    font-weight: 600;
}
.notifi-img-left img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 5px #aaa;
}

.notification-content-row {
    padding: 15px;
    border-bottom: 1px solid #ddd;
}
.notifi-content-right {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.notification-time span {
    color: #5b5b5b;
    font-size: 13px;
    font-weight: 500;
}
.notification-footer {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-bottom: 0;
    padding-top: 10px;
    box-shadow: 0px -4px 5px #f3f3f3;
    padding-bottom: 10px;
    cursor: pointer;
}
.notification-footer img {
    margin-left: 5px;
}
.notification-scroll {
    max-height: 60vh;
    min-height: 30vh;
}
.notification-dropdown .dropdown-toggle::after {
    display: none;
}
.notification-dropdown.dropdown {
    margin-right: 20px;
}
.unread-notif {
    background-color: #e9f2ff;
}
.read-notify {
    background-color: #fff;
}
.no-item {
    padding: 20px 10px;
    text-align: center;
    color: #777;
     margin-top: -10px;
}
.noti-count {
    background: $primary-color;
    padding: 2px;
    top: 2px;
    left: 11px;
    position: absolute;
    height: 18px;
    line-height: 14px;
    font-weight: bold;
    color: #fff;
    border-radius: 3px;
    z-index: 9;
    font-size: 12px;
}
.menu-items .dropdown .notification-btn .btn {
    background-color: transparent;
    color: $primary-color;
    padding: 0 10px;
    height: 20px;
    font-weight: 500;
}
.notification-dropdown .dropdown-menu ul .dropdown-item:last-child .notification-content-row {
    border: none;
}
.notification-btn {
    padding-right: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    text-align: right;
}
.notification-dropdown .dropdown-menu .dropdown-item:hover {
    cursor: pointer;
    background: #e2e8ef;
}
.view-less-arrow {
    transform: rotate(180deg);
    display: inline-block;
    position: relative;
    top: 2px;
    left: 5px;
}
.notification-dropdown .dropdown-menu .notification-scroll .notification-list {

    margin-right: -10px;
}
@media (max-width: 767px) {
    .notification-dropdown .dropdown-menu {
        width: 90%;
        margin: 0 auto 15px;
    }
    .notification-dropdown.dropdown {
        margin-right: 0;
        border: none;
        padding: 0;
    }
    .notification-dropdown .dropdown-menu {
        margin: 0 auto;
        transform: none !important;
        border: 1px solid #ddd !important;
    }
    .notification-dropdown .btn {
        margin-left: 25px;
        position: relative;
    }
    .noti-count {
        top: -10px;
        left: 13px;
        font-weight: 600;
    }
    .notification-mobile-only .notification-dropdown {
        position: inherit;
    }
    .notification-dropdown .dropdown-menu {
        width: 100%;
        box-shadow: 0 0 10px #ddd;
    }
    .notification-dropdown .dropdown-menu.show.dropdown-menu-end {
        top: 45px !important;
    }
    .notification-dropdown .dropdown-menu .notification-wrap::after {
       right: 67px;
       
    }
    .notification-list .dropdown-item {
        white-space: inherit;
    }
    .notification-mobile-only .notification-dropdown .notification-btn .btn {
        color: $primary-color;
    }
}
