.pop-checkboxscroll {
    height: 40vh;
}
.pop-selected-items-scroll {
    height: 20vh;
}
.selected-view-box {
    border-top: 1px solid #cccdce;
    border-left: none;
    border-right: none;
    padding-top: 20px;

}
.react-multi-select {cursor: pointer;}

.selected-item-show-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.selected-item-show {
    display: flex;
    background: #f3f5f7;
    margin: 0 5px 10px;
    padding: 10px;
    border-radius: $radius;
    font-size: 12px;
    position: relative;
}
.country-pop-items .checkbox-box {
    padding-top: 30px;
    padding-bottom: 20px;
}
.selected-close {
    margin-left: 10px;
    position: absolute;
    top: -3px;
    padding: 5px 7px;
    right: 0;
    cursor: pointer;
}
.selected-name {
    margin-right: 15px;
}
body .scrollarea .scrollbar-container.vertical .scrollbar {
    width: 4px;
    height: 20px;
    background: #000;
    margin-left: 1px;
    border-radius: 20px;
}
body .scrollarea .scrollbar-container.vertical {width: 4px;}
.country-pop-wraper {
    padding: 15px 0px;
}   
@media(max-width:767px){
    .pop-checkboxscroll {
        max-height: 40vh;
        height: auto;
    }
}

