.profile-inner-box {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.11);
    border-radius:  $curveradius;
    background: #fff;
    padding-bottom: 40px;
    height: 100%;
}
.profile-top-bg {
    height: 169px;
    background-color: $primary-color;
    background-position: center 0;
    border-radius:  $curveradius $curveradius 0 0 ;
}
.profile-stat-contentbox {
    margin-top: -80px;
}
.profile-quick-details {
    padding: 0 30px;
    text-align: left;
}
@media (max-width: 767px) {
    .profile-top-bg {
        height: 80px;
    }
}
