.chatbot-message-list .cs-message--outgoing {
    justify-content: normal;
}

.chatbot-message-list .cs-message-list__scroll-wrapper>.cs-message,
.cs-message-list__scroll-wrapper>.cs-message-group {
    max-width: 100%;
}

.chatbot-message-list .cs-message__content-wrapper {
    width: 100%;
}

.chatbot-message-list .query-input-wrap {
    border-radius: 5px;
    background: #def;
    padding: 13px;
    margin-bottom: 13px;
}

.chatbot-message-list .cs-message--outgoing .cs-message__content {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
}

.cs-chat-container {
    height: calc(100% - 100px);
    padding-bottom: 0px;
}

.query-input-message p {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
}

.chatbot-message-list,
.chatbot-message-list * {
    font-family: $primary-font;
}

.query-input-message {
    flex: 1;
    padding-left: 15px;
    min-height: 42px;
    display: flex;
    align-items: center;
}

.query-output-message-flex-wrap {
    display: flex;
    align-items: center;
    min-height: 42px;
}

.query-output-box {
    width: 100%;
}

.query-output-box .styledlink a {
    width: 14px;
    height: 14px;
    background: rgb(206, 228, 251);
    border-radius: 2px;
    overflow: hidden;
    text-decoration: none;
    color: $primary-color;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 11px;
    padding-top: 2px;
    position: relative;
    top: -4px;
    margin-right: 2px;

    @media(max-width:575px) {
        top: -3px;
        font-size: 9px;
        width: 12px;
        height: 12px;
    }

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.query-output-message p {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 15px;
}

.query-output-paragraph-wrap {
    min-height: 70px;

}

.query-output-wrap {
    border-radius: 5px;
    background: #fff;
    padding: 28px 22px;
    margin-bottom: 13px;
    border: 1px solid #E9E9E9;

    @media(max-width:575px) {
        padding: 14px 10px;
    }
}

.query-output-message {
    flex: 1;
    padding-left: 15px;
}

.query-output-action-icons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px 0 20px;
}

.query-action-icon {
    margin-bottom: 14px;
    cursor: pointer;
    // transition: all ease 0.5s;
}

.query-action-icon:hover svg path {
    fill: $primary-color;
}

// *****chat scrollbar move to outside whitebox*****
.cs-chat-container,
.cs-message-list {
    background-color: transparent;
}

// *****chat scrollbar move to outside whitebox*****

.cs-message-list .cs-message-list__typing-indicator-container {
    background-color: transparent;
    padding: 0;
    font-size: 15px;
    height: 30px;
    line-height: normal;
    left: 0px;
    bottom: 13px;
    position: absolute;
    right: 0;
    z-index: 2000;
}

.cs-message-list .typeindi-wrap {
    margin: 0 auto;
    position: relative;
    max-width: 700px;
    text-align: right;
    display: flex;
    justify-content: end;
    padding: 5px 0;
}

.cs-message-list .cs-typing-indicator {
    background-color: #fff;
    border-radius: 4px;
    position: inherit;
}

.cs-typing-indicator__dot {
    background-color: rgba(26, 115, 232, 0.5);
}

.cs-typing-indicator__text {
    color: rgba(26, 115, 232, 0.5);
    font-weight: 500;
}

.chat-section-whole-wrap .react-tooltip {
    padding: 5px 10px;
    font-size: 12px;
    z-index: 2001;
    background: $primary-color !important;
    color: #fff !important;
    border-radius: 6px;
}

.cs-chat-container {
    margin-right: -6px;
}

.cs-message {
    padding-right: 0;
}

@media (max-height: 900px) {
    .chatbot-message-list .query-input-wrap {
        padding: 7px 10px;
        margin-bottom: 10px;
    }
}

@media (max-height: 760px) {
    .query-output-wrap {
        padding: 7px 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .cs-chat-container {
        margin-right: 0;
    }

    .cs-message {
        padding-right: 0;
    }

    .query-bot-logo img {
        max-width: 30px;
    }

    .query-output-message {
        padding-left: 10px;
    }

    .cs-chat-container {
        height: calc(100vh - 360px);
    }

    .query-output-message p {
        line-height: 20px;
        min-height: 60px;
    }

    .query-action-icon {
        margin-bottom: 8px;
    }

    .query-input-message {
        padding-left: 0;
        min-height: 30px;
    }

    .cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
        margin-bottom: 0;
    }

    .custom-content-wrap {
        height: calc(100vh - 370px);
        overflow-y: scroll;
    }

    .query-input-message p {
        line-height: 20px;
    }

    .chatbot-message-list .query-input-wrap {
        margin-bottom: 6px;
    }

    .query-output-wrap {
        margin-bottom: 6px;
    }
}




.formatingParagraph {
    white-space: normal;
    font-size: 13px;
    line-height: 22px;

    h3 {
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: bold;
        line-height: 20px;

    }
    h4 {
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: bold;
        line-height: 20px;

    }

    p {
        margin-bottom: 1rem;
        line-height: 22px;
        font-size: 13px;
    }

    ul, ol {
        margin-bottom: 1rem;

        li {
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 10px;
        }
    }
}