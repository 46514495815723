.auth-signup-left {
    width: 358px;
    background: $primary-color;
    padding: 48px 40px;
    background-position: center;
    background-repeat: no-repeat;
}
.auth-signup-left .btn-outline-primary.upload-btn{
    color: white;
} 
.auth-signup-right {
    flex: 1;
    padding: 48px 62px;
}
.auth-signup-right .form-input-wrap {
    margin-bottom: 25px;
}
.signup-page-wrap {
    position: inherit;
}
.btn-outline-primary.upload-btn {
    font-weight: 500;
    font-size: 12px;
    border: 2px solid #f3f5f7;
    border-radius: $radius;
    padding: 0 15px;
    height: 39px;
}
.btn-outline-primary.upload-btn img {
    margin-right: 8px;
}
.or-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding: 15px 10px 0;
}
label.btn.upload-btn {
    line-height: 34px;
}
.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}
.img-preview {
    overflow: hidden;
}
button.btn.btn-white-outline.m-t-20 {
    border: 2px solid #e3e3e3;
    font-size: 13px;
    color: #fff;
    padding: 0 17px;
    line-height: 35px;
}
.profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 97px;
}
#fileUpload::-webkit-file-upload-button {
    display: none;
}
.upload-file-input {
    position: absolute;
    bottom: 6px;
    color: $primary-color;
    font-size: 11px;
}
.btn-outline-primary.upload-btn:hover {
    border: 2px solid #cbcbcb;
    background: #f3f5f7;
    color: $primary-color;
}
.croped-img-wrap img {
    width: 140px !important;
    height: 140px;
    border-radius: 50%;
    min-width: 140px;
    max-height: 140px;
    // background: #f0f0f0;
    padding: 2px;
    margin-top: 20px;
}
.image-crop-area {
    min-height: 200px;
}
.success-content-wrap h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 25px;
}
// .cropper-container {max-height: 400px;width: auto !important;height: auto !important;}

.eye-show,
.eye-hidden {
    position: absolute;
    right: 13px;
    top: 35px;
    cursor: pointer;
}
.eye-show img,
.eye-hidden img {
    max-width: 20px;
}
.eye-hidden img {
    opacity: 0.6;
}
.eye-icon-inputwrap .form-control.input-item {
    padding-right: 40px;
}
.agree-checkbox-wrap a {
    text-decoration: none;
}
.agree-checkbox-wrap .form-check-input {
    margin-top: 0;
    width: 18px;
    height: 18px;
}
.agree-checkbox-wrap .form-check-label {
    margin-left: 8px;
}
span.supported-file-text {
    display: block;
    position: absolute;
    /* bottom: 0; */
    font-size: 12px;
    color: #555;
}
.infoicon svg {
    width: 16px;
    margin-left: 8px;
    position: relative;
    top: -1px;
}
.infoicon {
    display: inline-block;
}
.infoicon svg path {
    fill: $primary-color;
}
.file-uploaded-text:empty {
    display: none;
}
.file-uploaded-text {
    font-size: 11px;
    color: green;
    font-weight: 500;
    margin: 0;
    position: relative;
    top: -11px;

}
.resume-remove-icon svg {
    margin-right: 0;
    width: 10px;
}
.resume-remove-icon {
    display: inline-block;
    width: 18px;
    line-height: 14px;
    border-radius: 50%;
    text-align: center;
    height: 18px;
    z-index: 100;
    cursor: pointer;

}
.resume-remove-icon:hover{
    opacity: 0.7;
}
@media (max-width: 991px) {
    .auth-signup-left {
        width: 100%;
        border-radius: 20px 20px 0 0;
        padding: 20px;
    }
    .auth-signup-left .signup-logo-wrap {
        text-align: center;
    }
    .auth-signup-right {
        padding: 20px 20px;
    }
    .profile-img {
        margin-top: 67px;
    }
}
@media (max-width: 767px) {
    // .auth-right-content .upload-btn-wrap {
    //     width: calc(100% - 100px);
    // }
    .auth-signup-right .form-input-wrap {
        flex-wrap: wrap;
    }

    .or-text {
        width: auto;
        padding: 0;
        font-weight: bold;
        text-align: left;
        height: 20px;
        margin-top: 38px;
        margin-left: 10px;
    }
    .file-upload-container .form-input-wrap {
        flex-wrap: wrap;
    }
    .Linkedinurl {
        width: 100%;
        margin-top: 15px;
    }
    .file-uploaded-text {
        position: absolute;
        top: 68px;
        overflow: hidden;
        max-width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
