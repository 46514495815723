.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}
.loader-3 .dot {
    width: 10px;
    height: 10px;
    background: #1A73E8;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 5px);
}

.loader-3 .dot1 {
    left: 0px;
    -webkit-animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
}

.loader-3 .dot2 {
    left: 20px;
    -webkit-animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
}

.loader-3 .dot3 {
    left: 40px;
    -webkit-animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
}
@-webkit-keyframes dot-jump {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes dot-jump {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}