.chat-feed-wrap {
    background-color: #fff;
    padding: 25px 10px 34px;
    border-radius:0 20px 20px 0;
    @media(max-width:991px){
        border-radius:0 0 20px 20px; 
    }
}
.feed-tiles-wrap {
    max-width: 700px;
    margin: 0 auto;
}
.chat-beta-text {
    color: #1a73e8;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.2px;
    border-radius: 2px;
    background: rgba(26, 115, 232, 0.2);
    display: block;
    margin-left: 8px;
    padding: 1px 3px;
}
.chat-header-inline h4 {
    margin: 0;
    color: #000;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.44px;
}
.chat-header-inline {
    margin-bottom: 9px;
}
.chat-feed-header p {
    color: rgba(37, 37, 37, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}
.chat-feed-header {
    margin-bottom: 50px;
}
.feed-whole-box{
    max-width: 870px;
    margin-left: auto;
    margin-right: auto;
}
@media(max-height: 900px){
    .chat-feed-header {
        margin-bottom: 35px;
    }
    .chat-feed-wrap {
        padding: 20px 10px;
    }
}
@media(max-height: 760px){ 
    .chat-feed-header {
        margin-bottom: 25px;
    }
    .chat-header-inline {
        margin-bottom: 5px;
    }
    .chat-feed-wrap {
        padding: 20px 10px;
    }
}
@media (max-width:767px){
    .chat-feed-wrap {
        padding: 10px 0px;
    }
    .cs-message-list__scroll-wrapper {
        padding-right: 0.8em;
    }
    .feed-tiles-wrap {
        padding-bottom: 0px;
        height: 100%;
    }
}