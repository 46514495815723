.banner-wrap {
    padding: 20px;
    background: #f3f5f7;
    border-radius: $radius;
}
p.banner-info-text {
    position: absolute;
    bottom: 45px;
    left: 35px;
    margin: 0;
    color: #555;
    font-size: 12px;
    font-style:italic;
}
.banner-content-box {
    background: linear-gradient(180deg, #4991ef 0%, #1a73e8 100%);
    box-shadow: 0px 10px 10px rgba(133, 175, 214, 0.45);
    padding: 12px;
    border-radius: $curveradius;
    position: relative;
}

.banner-textarea-wrap textarea.form-control {
    min-height: 100px;
    border: none;
    background: #f0f0f0;
    font-size: 14px;
    color: #5b5b5b;
    padding: 10px 18px;
    box-sizing: border-box;
    max-height: 170px;
    border-radius: $curveradius;
}
.banner-textarea-wrap .form-control::placeholder {
    font-style: italic;
    color: #8c8c8c;
}

.dragand-drop-style {
    min-height: 99px;
    background: #f3f5f7;
    border: 2px dashed #d6d7d8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-md;
    padding: 0;
    text-align: center;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 10px;
}
.dragand-drop-style p {
    margin-bottom: 0;
    font-size: 13px;
    color: #808080;
    line-height: 22px;
}
.drop-upload-icon {
    display: block;
    margin-bottom: 7px;
}

.tab-content .banner-tab {
    background: #fff;
    padding: 10px;
    border-radius: $curveradius;
    padding-bottom: 60px;
}
.banner-btn-wrap .btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.dragand-drop-style div:empty {
    display: none !important;
}
.dragand-drop-style .filename-uploaded {
    color: $primary-color;
    word-break: break-all;
}
.remove-uploaded-btn svg {
    width: 10px;
}
.remove-uploaded-btn svg path {
    fill: #fff;
}
.remove-uploaded-btn.btn{
    padding: 0;
    position: absolute;
    right: 20px;
    top: 7px;
    z-index: 100;
}
.length-exceeded {
    font-size: 10px;
    color: red;
}
@media (max-width: 767px) {
    .banner-wrap {
        padding: 10px 0;
    }
    .remove-uploaded-btn.btn {
        font-size: 10px;
        min-width: 80px;
    }
    .remove-uploaded-btn svg {
        width: 8px;
    }
    p.banner-info-text{
        display: none;
    }
}
