.expert-left-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.experts-box {
    background: #ffffff;
    box-shadow: 0px 1px 7px rgb(0 0 0 / 14%);
    border-radius: $curveradius;
    margin-bottom: 24px;
    display: flex;
}
.experts-box-left {
    width: 84px;
    background: #f3f5f7;
    display: flex;
    align-items: center;
    padding-left: 17px;
    border-radius: $curveradius 0 0 $curveradius;;
}
.experts-right {
    flex: 1;
    min-height: 188px;
    display: flex;
    align-items: center;
    padding-left: 50px;
}
.experts-right-content {
    width: 100%;
    padding-right: 20px;
}
.experts-right-content h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-bottom: 12px;
}
.experts-pers-info-wrap {
    margin-bottom: 12px;
}
span.experts-pers-info-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-left: 10px;
    color: #5b5b5b;
}
.experts-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}
@media (max-width:767px){
    .experts-right {
        padding-left: 50px;
    }
}