.shared-name-wrap {
    flex-wrap: wrap;
}
.shared-name-box {
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 10px;
    padding-right: 20px;
}
// .shared-name-box:nth-child(2) {
//     border: none;
// }
.shared-name-box:last-child {
    margin: 0;
    border: none;
}
.manage-access-popup .country-header-search {
    margin: 0;
    margin-bottom: 20px;
}
.share-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #5b5b5b;
}
.b-t-0 {
    border-top: none !important;
}
.shared-remove-btn {
    display: flex;
    align-items: center;
}
.shared-remove-btn span {
    margin-left: 5px;
    color: #5b5b5b;
}

.status-searchontop .pop-checkboxscroll {
    height: 58vh;
}
// .status-searchontop .access-box {
//     margin-top: 100px;
// }
.status-searchontop .css-4ljt47-MenuList {
    max-height: 162px;
}
.status-searchontop .css-yt9ioa-option {
    padding-top: 6px;
    padding-bottom: 6px;
}
.status-searchontop .css-26l3qy-menu {
margin-top: 2px;
}

@media(max-width:767px){
    .shared-remove-btn span {
        display: none;
    }
    .shared-name-box {
        padding-right: 0;
    }
}