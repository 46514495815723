.video-frame-wrap {
  display: flex;
  height: 222px;
  background: #555;
  border: 1px solid #ddd;
  border-radius: $radius-md;
}

.video-frame-wrap iframe {
  flex: 1;
  max-height: 100%;
  max-width: 100%;
  border-radius: $radius-md;
}
.react-player__preview {
  border-radius: $radius-md;
}
.hom-secs .multimedia-wrap .sourcebox {
  margin-right: 0;
}
.hom-secs .multimedia-wrap .sourcebox h3 {
  border: none;
  padding-bottom: 0;
}
.multimedia-wrap .slick-slide .sourcebox {
  border: none;
  padding-left: 12px;
  padding-right: 12px;
}
.multimedia-wrap .slick-list {
  margin: 0 -12px;
}
.hom-secs .multimedia-wrap {
  padding-right: 15px;
}
@media (max-width: 1400px) {
  .video-frame-wrap {
    height: 160px;
  }
}
@media (max-width: 767px) {
  .video-frame-wrap {
    height: 200px;
  }
  .hom-secs .multimedia-wrap {
    padding-right: 0;
  }
}
