.output-image-show-box {
    margin: 12px -6px 0;
}
.output-img-item {
    padding: 6px;
}
.output-img-item img {
    border-radius: 10px;
    width: 251px;
    height: 200px;
    object-fit: cover;
    height: 200px;
    object-position: center;
}
.download-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.download-icon:hover {
    opacity: 0.8;
    transition: all ease 0.5s;
}
.download-icon img {
    width: 21px;
    height: 21px;
}
.graph-result-box {
    background-color: #fff;
    padding: 12px;
    border-radius: 6px;
    margin-top: 15px;
}
// .graph-result-box canvas {
//     max-width: 100% !important;
//     max-height: 400px !important;
//     width: 100% !important;
//     height: 100% !important;
// }
.csv-link-btn {
    background-color: $primary-color;
    line-height: 30px;
    padding: 0 10px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    font-weight: 500;
}
.csv-link-btn:hover {
    color: #fff;
    opacity: 0.9;
}
@media (max-width: 767px) {
    .output-img-item {
        width: 50%;
    }
    .output-img-item img {
        border-radius: 10px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
    .output-image-show-boxes {
        margin: 0 -45px 0 -40px;
    }
    .download-icon {
        right: 3px;
        top: inherit;
        bottom: 3px;
        background: #1a73e8;
        border-radius: 50%;
    }
    .download-icon svg rect {
        fill: transparent !important;
    }
    .download-icon svg path {
        fill: #fff;
    }
}
