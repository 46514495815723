// .feed-tiles-row {
//     margin: 0 -10px;
// }
// .feed-tiles-box {
//     padding: 0 10px;
//     width: 50%;
// }
// .tile-sample-item {
//     background: #f3f5f7;
//     padding: 15px 20px;
//     margin-top: 13px;
//     text-align: center;
//     height: 98px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
// .feed-tile-box-header {
//     text-align: center;
// }
// .feed-tile-box-header h4 {
//     color: #000;
//     font-size: 15px;
//     font-weight: 500;
//     letter-spacing: -0.3px;
//     margin: 0;
//     margin-top: 5px;
// }
// .tile-sample-item p {
//     margin: 0;
//     max-width: 220px;
// }
// .feed-tiles-infobox {
//     margin-top: 37px;
// }
// .feed-tiles-infobox p {
//     margin: 0 auto;
//     line-height: 20px;
//     max-width: 77%;
// }
// .feed-tiles-infobox p a {
//     font-weight: 600;
//     text-decoration: none;
// }
// .feedback-link {
//     color: $primary-color;
//     cursor: pointer;
// }
// @media (max-width: 1366px) {
//     .tile-sample-item p {
//        font-size: 13px;
//     }
// }
// @media (max-height: 900px) {
//     .tile-sample-item {
//         padding: 12px 15px;
//         height: 90px;
//     }
//     .tile-sample-item p {
//         max-width: 250px;
//     }
//     .feed-tiles-infobox {
//         margin-top: 20px;
//     }
// }
// @media (max-height: 760px) {
//     .tile-sample-item {
//         padding: 6px 12px;
//         height: 70px;
//         margin-top: 8px;
//     }
// }
// @media (max-width: 767px) {
//     .feed-tiles-row {
//         flex-wrap: wrap;
//         width: 100%;
//         flex: 1;
//         margin: 10px 0;
//         display: block !important;
//     }
//     .feed-tiles-box {
//         margin-bottom: 30px;
//     }
//     .feed-tiles-box {
//         width: 100%;
//     }
// }

.personality-slider-wrap {
    width: calc(100vw - 400px);
    max-width: 100%;
    overflow: hidden;
    @media (max-width:1200px) {
        width: calc(100vw - 340px);
    }
    @media (max-width:991px) {
        width: calc(100vw - 70px);
    }
    @media (max-width:575px) {
        width: calc(100vw - 30px);
    }
    .slider-item {
        padding: 2px 10px;
        &.active {
            .personality-item-box {
                border: 1px solid rgba(26, 115, 232, 1);
                box-shadow: 0 0 0 2px rgba(26, 115, 232, 1), 0 1px 2px rgba(26, 115, 232, 0.3);;
            }    
        }
    }
}

.personality-item-box {
    border: 1px solid #D5D8DC;
    border-radius: 15px;
    padding: 20px 15px 15px 15px;
    height: 100%;
    @media (max-height:770px) {
        padding: 12px 10px 10px 12px;
    }
    h3 {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -2%;
        line-height: 19px;
        margin-bottom: 6px;
    }

    p {
        color: rgba(37, 37, 37, 0.8);
        line-height: 22px;
        margin: 0;
        @media (max-height:770px) {
            line-height: 20px;
        }
        @media (max-height:730px) {
         font-size: 12px;
        }
    }
}

.personality-slider-wrap .slick-list,
.personality-slider-wrap .slick-track,
.personality-slider-wrap .slider-item {
    display: flex;
    align-items: stretch;
}

.personality-slider-wrap .slick-slide>div,
.personality-slider-wrap .slider-item {
    height: 100%;
}

.pers-slider-icon {
    width: 70px;
    height: 70px;
    border: 1px solid #D0D0D0;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-height:770px) {
        width: 50px;
        height: 50px;
    }
    
}

.personality-slider {
    .pers-slick-next-wrap {
        right: -12px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border: transparent;
        box-shadow: 0px 4px 50px 5px rgba(198, 198, 198, 0.32);
        border-radius: 50%;
        background-color: $primary-color;
        z-index: 9;
        

        &::before {
            display: none;
        }
        &.slick-disabled {
            opacity: 0;
        }
    }
    .pers-slick-prev-wrap {
        left: -12px;
        width: 36px;
        height: 36px;
        line-height:36px;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: 0px 4px 50px 5px rgba(198, 198, 198, 0.32);
        border-radius: 50%;
        background-color: $primary-color;
        z-index: 9;

        &::before {
            display: none;
        }
        &.slick-disabled {
            opacity: 0;
        }
    }
}
.personality-slider-container {
    margin: 0 auto;
    max-width: 1090px;
    padding: 0 17px;
}
.pers-slider-title {
    font-size: 20px;
    margin-bottom: 18px;
    line-height: 24px;
    font-weight: 600;
    padding-left: 10px;
}
.personality-scrollbar {
    max-height:500px;
    
}