.sourcebox h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
}
.sourcebox p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #5b5b5b;
}
.icon-button {
    padding: 0;
    border: none;
    background: transparent;
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
}
.icon-button:last-child {
    margin-right: 0;
}
.source-footer {
    display: flex;
    align-items: center;
}
.like-count {
    margin-right: 6px;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    margin-top: 2px;
    min-width: 12px;
}
.liked svg {
    width: 22px;
}
.icon-button .liked-icon {
    display: none;
}
.icon-button.liked .normal-icon {
    display: none;
}
.icon-button.liked .liked-icon {
    display: inline-block;
}

.slick-slide .sourcebox {
    border-left: 1px solid #d9d9d9;
    padding-left: 15px;
    padding-right: 15px;
}
.slick-slide:first-child .sourcebox{
    border: none;
    padding-left: 0px;
}
.source-label {
    background: #eff0f2;
    border-radius: 13px;
    padding: 4px 10px;
    color: #5b5b5b;
    font-size: 12px;
}
.multi-label-wrap .source-label {
    margin-left: 10px;
}
.multi-label-wrap .source-label:first-child {
    margin-left: 0;
}
.hom-secs .slider-item .sourcebox {
    flex: 1;
}
.hom-secs .sourcebox h3 a:hover{
    color: $primary-color !important;
    display: block;
}
.source-content {
    min-height: 100px;
}
.showmore-button {
    font-size: 12px;
}
.hom-secs .sourcebox h3 {
    word-break: break-word;
}
@media (min-width: 768px) {
    .hom-secs .sourcebox h3 {
        height: 48px;
        overflow: hidden;
        padding-bottom: 0;
        line-height: 22px;
    }
}
@media (max-width: 767px) {
    .sourcebox h3 {
        margin-bottom: 5px;
    }
    .sourcebox {
        margin-right: 0;
    }
    .slick-slide .sourcebox {
        border-left: none;
        padding-left: 0;
        padding-right:0;
    }
}
