.custom-pagination .page-item.active .page-link {
    background-color: $primary-color;
    border: none;
    color: #fff;
}
.custom-pagination .page-item .page-link {
    border: none;
}
.custom-pagination .paginationStyle {
    border: none;
}
.custom-pagination.pagination-sm .page-link {
    padding: 0;
    font-size: 13px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 4px;
    background-color: transparent;
    color: #000;
}
.pagination-sm .page-item:first-child .page-link {
    background-color: #e9e9e9;
    font-size: 20px;
}
.pagination-sm .page-item:last-child .page-link {
    background-color: #e9e9e9;
    font-size: 20px;
}
.pagination-sm .page-item:first-child .page-link span,
.pagination-sm .page-item:last-child .page-link span {
    position: relative;
    top: -1px;
}
.custom-pagination li.page-item {
    padding: 0 10px;
}
.custom-pagination {
    margin: 30px -10px 0;
}
.custom-pagination li.page-item.disabled .page-link,
.custom-pagination .paginationStyle .page-link {
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.custom-pagination li.page-item.disabled .page-link {
    color: #9c9494;
}
.custom-pagination .page-link:focus {
    box-shadow: none;
}

.react-new-pagination .page-item .page-link {
    border: none;
    font-weight: 500;
    color: #878a8d;
    font-size: 14px;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    min-width: 30px;
    text-align: center;
}

.react-new-pagination li.page-item {
    padding: 0 6px;
}

.react-new-pagination .page-item.active .page-link {
    background-color: $primary-color;
    border: none;
    color: #fff !important;
    border-radius: 4px;
}
.react-new-pagination .page-item.page-item-arrow .page-link {
    font-size: 22px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    background: #f0f0f0;
    border-radius: 4px !important;
    height: 32px;
    width: 32px;
    padding: 0;
    text-align: center;
    color: #333;
}
.react-new-pagination .page-item.page-item-arrow.disabled .page-link {
    color: #999;
}
.react-new-pagination .page-link:hover {
    border-radius: 4px;
}

.react-new-pagination .page-item:not(:first-child) .page-link {
    margin-left: 0;
}
.page-link:focus {
    box-shadow: none;
    opacity: 0.8;
}
@media (max-width: 767px) {
    .custom-pagination li.page-item {
        padding: 0 3px;
    }
}
