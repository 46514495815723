.signup-content-profile {
    border: 1px solid #e9e9e9;
    border-radius: $curveradius;
}
.prof-sub-header {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #5b5b5b;
    letter-spacing:0.2px;
    margin: 0;
}
.inner-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
}
.my-profile-header .inner-title {
    margin: 0;
}
.profile-det-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #5b5b5b;
    margin-bottom: 5px;
}
.profile-det-box h4 {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 20px;
}
.profile-det-box {
    width: 50%;
    min-height: 130px;
    padding: 0 30px 20px;
    position: relative;
}
.border-detail {
    margin-bottom: 50px;
}
.border-detail::after {
    content: "";
    left: 30px;
    right: 30px;
    bottom: 0;
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
}
.profile-det-box-wrap {
    margin: 0 -30px;
}
.p-b-0 {
    padding-bottom: 0;
}
.box-bg {
    background: #f3f5f7;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    border-radius: $radius;
}
.box-bg  .react-multi-select .css-1s2u09g-control {
    background-color: #fff;
}
.box-bg .css-1rhbuit-multiValue {
    background-color: #f3f5f7;
}
.box-bg-infotext {
    color:$primary-color;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
}
.subheader-min-height {min-height: 39px;}
@media (min-width: 768px) and (max-width: 991px) {
.profile-det-box-wrap .border-detail {
    margin-bottom: 20px;
}
.profile-det-box {
    padding-bottom: 20px;
    min-height: inherit;
}
}

@media (max-width: 767px) {
    .profile-det-box {
        width: 100%;
        min-height: inherit;
    }
    .border-detail, .profile-det-box {
        margin-bottom: 20px;
    }
    .border-detail::after {
        display: none;
    }
    .profile-det-box h4 {
        margin-bottom: 10px;
    }
    .profile-wrap .p-30 {
        padding: 15px;
    }
    .innerwrapper.profile-wrap::before {
        background: transparent;
    }
}
