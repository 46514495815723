.recomendation-header{
    border-bottom: 1px solid #D7E0E9;
    padding-bottom: 20px;
}
.recomendation-main-outer {
    min-height: 100vh;
}
@media(max-width:767px) {
    .recomendation-header .backarrow-wrap{
        align-items: flex-start !important;
    }
    .financerecomendation-wrap {
        padding-bottom: 40px;
    }
}