.common-modal .modal-content {
    border: none;
    border-radius: $curveradius;
    outline: 0;
    box-shadow: 0px 50px 50px rgb(0 0 0 / 4%);
}
.common-modal .modal-sm.modal-dialog {
    max-width: 390px;
}
.common-modal .modal-dialog .modal-content {
    padding: 25px;
}
.modal.side-popup {
    left: inherit;
    width: auto;
    right: 0;
}
.side-popup .modal-dialog.modal-md {
    width: 540px;
    max-width: inherit;
    margin-top: 20px;
}
.side-popup .modal-dialog.modal-lg {
    width: 720px;
    max-width: inherit;
}
.side-popup .modal-dialog .modal-content {
    min-height: calc(100vh - 50px);
}
.side-popup .modal-header {
    border-bottom: 1px solid #cccdce;
    font-size: 14px;
    padding: 14px 7px 14px;
    justify-content: space-between;
}
.side-popup .modal-header .modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
}
.side-popup .modal-header .btn-close {
    padding: 0;
    opacity: 1;
    margin: 0;
}
.common-modal .btn-close {
    opacity: 1;
}
.common-modal .modal-body {
    padding: 0;
}
.common-modal .modal-header {
    padding: 0 0 7px;
}
.common-modal.cropModal .modal-header {
border: none;
}
.common-modal .modal-title {
    font-weight: 600;
    font-size: 18px;
}
.reload-popup.common-modal .modal-sm.modal-dialog {
    max-width: 480px;
}
.reload-popup .upgrade-pop-wrap .upgrade-title {
    max-width: 420px;
}
.reload-popup .upgrade-pop-wrap.no-results .upgrade-title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
}
.reload-popup.common-modal .modal-header {
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
}
.country-modal-inotext img {
    max-width: 16px;
}
.country-modal-inotext {
    margin-left: 10px;
}
.country-pop-with-toolitip .modal-header {
    position: absolute;
    top: 31px;
    right: 25px;
    z-index: 1000;
    border: none;
}
.country-pop-with-toolitip.common-modal .modal-title {
    border-bottom: 1px solid #cccdce;
    padding-bottom: 7px;
    line-height: 22px;
}
@media(max-width:767px){
    .side-popup .modal-dialog.modal-md {
        width: auto;
        max-width: 100%;
        margin-top: 20px;
    }
    .modal.side-popup {
        width: 100%;
    }
    .common-modal .modal-sm.modal-dialog {
        max-width: 100%;
    }
}
