.output-relatedarticle-wrap {
    border-radius: 5px;
    background: #f3f5f7;
    padding: 13px;
    margin: 15px 0;
}
.output-relatedarticle-wrap h4,
.output-relatedarticle-wrap h4 a {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    line-height: 32px;
}
.output-relatedarticle-wrap h4 a span {
    font-weight: 600;
}
.rel-article-item {
    width: 33.3333%;
    padding: 0 8px;
    margin-bottom: 20px;
}
.rel-article-text,
.rel-article-text a {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
}
.rel-article-text {
    flex: 1;
}
.rel-articles-row {
    margin: 0 -8px;
}
.rel-article-img img {
    margin-right: 12px;
}
.more-article {
    border-radius: 16px;
    padding: 0px 10px;
    font-size: 13px;
    line-height: 26px;
    display: inline-flex;
    height: 30px;
    align-items: center;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.rel-article-footer {
    margin-top: 10px;
    border-top: 1px solid #bed1ea;
    padding-top: 13px;
    padding-bottom: 2px;
}
.rel-article-img img {
    max-width: 70px;
}
.ai-relatedarticle-label-wrap {
    margin-top: 10px;
}
.ai-related-article-bubble {
    background: #E4E4E4;
    border-radius: 16px;
    padding: 4px 10px;
    color: #5b5b5b;
    font-size: 12px;
    margin-right: 5px;
    margin-top: 5px;
}
.ai-related-article-bubble:first-child {
    margin-left: 0;
}
.rel-article-text {
    min-height: min-content;
}

@media (max-width: 767px) {
    .output-relatedarticle-wrap h4,
    .output-relatedarticle-wrap h4 a {
        line-height: 22px;
    }
    .rel-article-item {
        width: 100%;
        padding: 0;
    }
    .rel-articles-row {
        margin: 0;
        flex-wrap: wrap;
    }
    .rel-article-footer {
        margin-top: 0;
    }
    .output-relatedarticle-wrap {
        margin: 7px 0;
    }
}
