.output-learnmore-wrap {
    margin: 30px -45px 0 -58px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #E9E9E9;
    padding-top: 8px;
    padding-bottom: 1px;
    padding-left: 50px;
    padding-right: 50px;
}
.learnmore-child-labels span,
.learnmore-child-labels a {
    border-radius: 16px;
    background: #f3f5f7;
    padding: 0px 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: inline-flex;
    height: 30px;
    align-items: center;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.learnmore-child-labels {
    padding: 0 5px;
    margin-top: 6px;
}
.learnmore-child {
    height: 30px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
    font-style: normal;
    padding-right: 8px;
    margin-top: 3px;
    width: fit-content;
}
.output-learnmore-content-list a,
.view-more-item {
    border-radius: 16px;
    background: #f3f5f7;
    padding: 0px 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: inline-flex;
    height: 30px;
    align-items: center;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.view-more-item {
    border: none;
}
.bold-text {
    font-weight: 600;
}
.items-showing-count {
    position: absolute;
    right: 0;
    top: 19px;
    font-size: 13px;
}
.output-learnmore-content {
    flex: 1;
    padding-right: 30px;
}
.output-learnmore-content ul.output-learnmore-content-list {
    display: inline-flex;
    margin: 0 -5px;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
}
.output-learnmore-content-list li {
    padding: 0 5px;
    margin: 3px 0;
}
button.view-more-item {
    min-width: 40px;
    text-align: center;
    display: block;
    padding: 0px 10px;
    font-size: 13px;
    line-height: 26px;
    height: 30px;
    align-items: center;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
@media (max-width: 767px) {
    .output-learnmore-wrap {
        margin: 30px -46px 0 -39px;
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column;
    }
    .output-learnmore-content-list a,
    .view-more-item {
        padding: 0px 8px;
        font-size: 11px;
        height: 26px;
    }
    button.view-more-item {
        font-size: 11px;
        height: 26px;
    }
}
