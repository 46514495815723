#scrollableDiv {
    height: calc(100vh - 400px);
    overflow-y: auto;
    margin-right: -19px;
    margin-left: -8px;

    .infinite-scroll-component__outerdiv {
        padding-right: 7px;
    }

    @media(max-width: 767px) {
        height: calc(100vh - 280px);
    }
}

.previous-chat-boxes {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #cfd7e1;
        outline: none;
        border-radius: 10px;
        cursor: all-scroll;
    }

    margin-right: 0;
}

.previous-chat-box {
    padding-top: 30px;
    margin-bottom: 20px;
    border-top: 1px solid #d9d9d9;
    padding-right: 0;
    padding-left: 8px;

    h3 {
        color: #5b5b5b;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.3px;
    }

    &:first-child {
        border-top: none;
        padding-top: 0;
    }

    @media(max-height: 900px) {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    @media(max-height: 800px) {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    @media(max-height: 760px) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}

.side-query-row {
    padding: 2px 0 0 8px;
    background-color: transparent;
    border-radius: 6px;
    position: relative;
    left: -8px;

    &:hover {
        background-color: #f3f5f7;
        transition: all ease 0.5s;
        .side-query-more {
            opacity: 1;
        }
    }
}

.side-query-text {
    display: flex;
    align-items: center;
    flex: 1;

    @media(max-height: 900px) {
        font-size: 13px;
        line-height: 26px;
    }
}
.side-query-row:hover {
    .side-query-text-content {
        &::after {
            display: none;
        }
    }
}
.side-query-text-content {
    color: #828a92;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    flex: 1;
    max-width: 230px;
    &::after {
        position: absolute;
        width: 40px;
        height: 20px;
        content: "";
        position: absolute;
        right: 0;
        top: 7px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
        
    }
}

.side-query-icon {
    width: 30px;
}

.side-query-more {
    position: absolute;
    right:3px;
    bottom: 0;
    top: 0;
    display: flex;
    opacity: 0;
    align-items: center;
    padding-left: 8px;
    background-color: #f3f5f7;
    z-index: 100;
    @media(max-width:991px){
        opacity: 1;
        background-color: transparent;
    }
    .chat-more-dpdn {
        width: 20px;
        min-width: 0;
        padding: 0;
        background: transparent;
        text-align: center;
        height: auto;

        &:active {
            background-color: transparent;
            border: none;
        }

        &:hover {
            svg {
                rect {
                    fill-opacity: 1;
                    transition: all ease 0.5s;
                }
            }
        }

        &.dropdown-toggle::after {
            display: none;
        }
    }

    .dropdown-menu {
        border: none;
        box-shadow: 0 0 5px #999;
        min-width: 0;
        margin-right: 8px;
    }

    a.dropdown-item {
        font-size: 13px;
    }
}

.previous-chat-text {
    color: #5b5b5b;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.26px;
    margin-bottom: 20px;
}

.no-history-box {
    background: #f3f5f7;
    padding: 20px 12px;
    border-radius: 10px;

    h3 {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        margin-bottom: 12px;
    }

    p {
        font-size: 13px;
        color: #5b5b5b;
        line-height: 22px;
        margin: 0;
    }
}

.chat-side-loader-middle {
    display: flex;
    min-height: 120px;
    align-items: center;
    justify-content: center;
}

.query-edit-input {
    flex: 1;

    .side-query-edit-input {
        border: 1px solid #ddd;
        border-radius: 4px;
        height: 28px;
        padding: 0 5px;
        width: 100%;
        margin-bottom: 2px;

        &:focus,
        &:focus-visible {
            border: 1px solid #ddd;
            outline: none;
        }
    }
}

.query-edit-action-icon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    path {
        fill: $primary-color;
    }

    svg {
        width: 18px;
        height: 18px;
    }
}

.query-edit-action-icons {
    width: fit-content;
    padding-left: 10px;
}

.side-query-edit-row {
    width: 100%;
}

.previous-chat-scroll {
    @media(max-height: 800px) {
        max-height: calc(100vh - 370px);
    }

    @media(max-height: 760px) {
        max-height: calc(100vh - 350px);
    }
}