.land-contact-section-bg {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width:50%;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    top: 0;
    background-position: top;
}
.land-contact-section .textarea-item {
    height: 70px;
    border: none;
    max-height: 70px;
    min-height: 70px;
}
.contact-header label {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}


.PhoneInputCountry {
    width: auto;
    position: relative;
    font-size: 12px;
    padding-left: 58px;
}
.input-item.PhoneInput {
    display: flex;
    width: 100%;
}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    position: absolute;
    width: 58px;
    height: auto;
    left:0px;
    top: 0;
}
select.PhoneInputCountrySelect{
    background-color: #f3f5f7;
    border-radius: 8px;
    height: 39px;
    border: none;
    font-size: 12px;
    width: 200px;
    padding-left: 7px;
}
.PhoneInputInput {
    background-color: #f3f5f7;
    border-radius: 8px;
    height: 39px;
    border: none;
    font-size: 12px;
    width: 100%;
    margin-left: 15px;
    padding: 0 10px;
}
img.PhoneInputCountryIconImg {
    border-radius: 3px 0 0 3px;
}
input.PhoneInputInput:focus, select.PhoneInputCountrySelect:focus {
    border: none;outline: none;
}
.land-contact-formwrap {
    padding: 0 0 0 40px;
}
.land-contact-formwrap .landing-common-title {
    max-width: 100%;
}
@media (min-width: 768px){
    .PhoneInputInput {
        width: calc(50% + 1px);
        margin-left: 24px;
    }
    .PhoneInputCountry {
        width: 50%;
    }
    select.PhoneInputCountrySelect {
        width: 100%;

    }
}

@media (min-width: 1400px){

    .land-contact-section-bg {
        width: 46%;
    }

}
@media (min-width: 768px) and (max-width: 991px) {
.land-contact-section-bg {
    display: none;
}
.land-contact-space{
    display: none;
}
.land-contact-formwrap {
    padding: 0;
}
}
@media (max-width: 767px) {
    .land-contact-formwrap {
        padding: 0;
    }
.land-contact-section-bg {
    position: inherit;
    height: 300px;
    width: 100%;
    background-position: top;
}
.land-contact-section {
    padding: 0px 0 30px;
}
.input-item.PhoneInput {
    flex-wrap: wrap;
}
.PhoneInputInput {
    margin-left: 0;
    margin-top: 10px;
}
.PhoneInputCountry {
    width: 100%;
}
select.PhoneInputCountrySelect {
    width: 100%;
}
.contact-header {
    text-align: center;
}
.landing-section-8 {
    padding: 30px 0 40px;
}
.land-contact-section form .btn {
    width: 100%;
}
}