.experience-slider-box h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 16px;
}
.experience-slider-box {
    padding: 40px 20px;
    background:#DDEAFC;
    min-height: 191px;
    box-shadow:0px 2px 5px 0px #D5DFED; border-radius:10px;
}
.land-owl-prev {
    position: absolute;
    left: -50px;
    top: 50%;
    margin-top: -35px !important;
    background: transparent !important;
    border: none;
    color: $primary-color !important;
    font-size: 50px !important;
    width: auto !important;
    height: auto !important;
    line-height: 50px !important;
    padding: 0 !important;
}
.land-owl-next {
    position: absolute;
    right: -50px;
    top: 50%;
    margin-top: -35px !important;
    background: transparent !important;
    border: none;
    color: $primary-color !important;
    font-size: 50px !important;
    width: auto !important;
    height: auto !important;
    line-height: 50px !important;
    padding: 0 !important;
}
.landing-section-7 .owl-carousel {
    position: relative;
}
.land-experience-slider-wrapper {
    margin-top: 50px;

    .owl-carousel .owl-stage-outer{padding:10px 0 30px;}
}
.landing-section-7 .landing-common-description {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.land-owl-next span, .land-owl-prev span {
    position: relative;
    top: -1px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .land-owl-next {
        right: -30px
    }
    .land-owl-prev {
        left: -30px;
    }
    .experience-slider-box h4 {
        font-size: 16px;
     
    }
}
@media (max-width: 767px) {
    .landing-section-7 .landing-common-description {
        max-width: 100%;
    }
    .experience-slider-box {
        padding-bottom: 60px;
        min-height: 232px;
    }
    .land-owl-next {
        right: calc(50% - 40px);
    }
    .land-owl-prev {
        left: calc(50% - 40px);
    }
    .owl-nav .land-owl-next,
    .owl-nav .land-owl-prev {
        top: inherit;
        bottom: 15px !important;
        height: 30px !important;
        width: 30px !important;
        line-height: 20px !important;
        font-size: 22px !important;
        background: #ddeafc !important;
        color: #000 !important;
    }
}
