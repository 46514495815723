.chat-section-whole-wrap {
    height: calc(100vh - 122px);
    background: #f3f5f7;
    padding: 22px 0;
}

.chat-sidebar-box {
    padding: 0;
}
.chat-feed {
    padding: 0;
    border-left: 1px solid #E9E9E9;

}
.chat-sidebar {
    width: 317px;
    padding: 0 24px 20px;
    background-color: #fff;
    border-radius: 20px 0 0 20px;
}

.chat-feed {
    flex: 1;
}
.chat-results-scroll {
    max-height: 70vh;
}
.chat-results-show-box {
    padding-bottom: 20px;
}
.chat-height-control {
    height: 100%;
}
.newchat-center-fix {
    display: flex;
    align-items: center;
    width: 100%;
    .chat-textarea-wrap {
        position: inherit;
        padding-top: 80px;
        @media (max-height:730px) {
            padding-top: 60px;
        }

    }
    .chat-height-control {
        height: auto;
    }
}

@media (max-width: 1200px) {
    .chat-sidebar {
        width: 270px;
        padding: 0 10px 20px;
    }
}
@media (min-width: 992px) {
    .mobil-header {
        display: none !important;
    }
    .mobile-menu-view-button {
        display: none;
    }
}

@media (max-width: 991px) {
    .chat-sidebar {
        width: auto;
    }
    .mobil-header {
        display: flex;
        padding: 6px 20px;
        background: $primary-color;
        border-radius: 20px 20px 0 0;
    }
    .mobil-menu-slide {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
        width: 100%;
        z-index: 99;
        transition: all 0.4s;
    }
    .ai-mobilemenu-active {
        transform: translateX(0);
        visibility: visible;
        background: #fff;
        z-index: 2000;
    }
    .mobil-menu-slide .sidebar-header {
        display: none;
    }
    .mobile-menu-close-button {
        z-index: 2001;
        position: fixed;
        right: 20px;
        top: 14px;
        cursor: pointer;
    }
    .mobile-menu-view-button svg,
    .mobile-menu-close-button svg {
        width: 30px;
        height: 30px;
    }
    .mobile-menu-view-button {
        cursor: pointer;
    }
    .mobile-menu-view-button svg path {
        fill: #fff;
    }
    .mobile-chat-logo h3 {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin: 0;
    }
    .mobile-chat-logo .poweredby-text {
        font-size: 12px;
        color: #fff;
    }
    .chat-sidebar-box {
        padding: 0;
    }
    .mobile-menu-view-button .mob-close-btn {
        display: none;
    }
    .mobile-menu-close-button .mob-open-btn {
        display: none;
    }
    .mobile-menu-close-button .mob-close-btn {
        display: block;
    }
    .mob-close-btn {
        font-size: 44px;
        line-height: 30px;
        color: #000;
        font-weight: 300;
    }
}
@media (max-width: 767px) {
    .chat-feed {
        flex: inherit;
        width: 100%;
        padding-bottom: 0;
        border: none;
    }
    .newchat-center-fix .chat-textarea-wrap {
        position: fixed;
    }
    .chat-section-whole-wrap {
        height: auto;
        // height: calc(100vh - 240px);
        padding-bottom: 0;
        min-height: inherit;
        padding-top: 5px;
         min-height: calc(100vh - 150px);
        // overflow-y: scroll;
    }
    .newchat-center-fix {
        .chat-textarea-wrap {
            padding-top:50px;
    
        }
      }

}
