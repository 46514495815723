.landing-section-3 {
    background-color: #ddeafc;
}
.landing-section-3 {
    padding: 80px 0 110px;
}
.getresults-content {
    padding: 0 15px 0 45px;
}
.animation-box-content {
    background: #fff;
    padding: 30px 10px;
    box-shadow: 0px 20px 20px rgb(59 89 134 / 25%);
    border-radius: 10px;
    height: 100%;
    position: relative; overflow:hidden;
}
.animation-box {
    padding: 0 11px;
    width: 20%;
}
.animation-box h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
}
.animation-box p {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    margin: 0;
}
.animation-box-row {
    margin: 40px -11px 0px;
}
.animation-section-big-row {
    margin: 0 -50px;
}

.landing-section-3 {
    overflow-x: hidden;
}

.animation-box:hover .animation-box-content::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    height: 7px;
    background: $primary-color;
    content: "";
}
.transforming-animation-wrap .landing-section-bg {
    width: 41.66666667%;
}

@media (max-width: 1200px) {
    .animation-box-row {
        flex-wrap: wrap;
        justify-content: center;
    }
    .animation-box {
        width: 33.3333%;
        margin-top: 20px;
    }
    .animation-section-big-row {
        margin: 0;
    }
    .getresults-content p br{display:none;}
}
@media (min-width: 768px) and (max-width: 991px) {
    .landing-section-3 {
        padding: 50px 0 60px;
    }   
}
@media (max-width: 767px) {
    .animation-box {
        width: 100%;
    }
    .transforming-animation-wrap .landing-section-bg {
        width: 100%;
    }
    .landing-section-3 {
        padding: 0 0 30px;
    }
    .animation-box-content {
        padding: 20px;
        box-shadow: 0px 0px 7px rgb(59 89 134 / 25%);
    }
    .animation-box h4 {
        margin-bottom: 12px;
    }
    .animation-box-row {
        margin: 10px 0 0px;
    }
    .animation-box {
        padding: 0 20px;
    }
    .getresults-content{padding:0 15px;}
}
