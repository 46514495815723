.catogory-popup .modal-dialog {
    max-width: 616px;
}
.catogory-checkbox .checkbox-wrapper {
    margin-bottom: 25px;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .catogory-checkbox .checkbox-wrapper {
        margin-bottom: 15px;
        margin-top: 10px;
    }
 }