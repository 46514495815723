.land-section-1-row {
    height: 478px;
}
.landing-section-1 {
    background-color: $primary-color;
}
.landing-section-1 h4{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 30px;
}

.landing-section-1 h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 30px;
}
.landing-section-1 h2 .Typewriter{margin-right:10px;}
.landing-section-1 h2 .Typewriter .Typewriter__wrapper{padding-right:10px;}
.landing-section-1 h2 .Typewriter .Typewriter__cursor{font-weight:400;}
.landing-section-1 h2 .Typewriter:last-of-type .Typewriter__cursor{display:none;}

.landing-section-1 p {
    font-size:15px;
    line-height: 22px;
    color:#fff;
    margin-bottom: 30px;
}
span.svg-down-arrow {
    margin-left: 6px;
    position: relative;
    top: 3px;
}
.sec1-content .btn {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.02em;
}
.sec1-content {
    padding-right: 50px;
}
@media (max-width: 1400px) {
    .landing-section-1 h4{font-size:20px; margin-bottom:25px;}
    .landing-section-1 h2 {font-size: 30px; margin-bottom:25px;}
    .landing-section-1 p{font-size:13px;}
}
@media (max-width: 1200px) { 
    .landing-section-1 h4{font-size:17px;}
    .landing-section-1 h2 {
        font-size: 26px;
        line-height: 30px;
    }
}
@media (max-width: 991px) { 
    .landing-section-1 h4{font-size:15px; margin-bottom:15px;}
    .landing-section-1 p{font-size:12px; margin-bottom:20px;}
    .sec1-content{padding-right:10px;}
}
@media (min-width: 768px) and (max-width: 991px) {
.landing-section-1 h2 {
    font-size: 20px;
    line-height:26px;
    margin-bottom: 15px;
}
.land-section-1-row {
    height: 290px;
}
}
@media (max-width: 767px) {
    .land-section-1-row {
        height: auto;
        padding: 30px 0;
    }
    .sec1-content {
        padding-right: 0;
        text-align: center;
    }
    .landing-section-1 h2 {
        font-size: 22px;
        line-height: 28px; margin-bottom:15px;
    }
 }