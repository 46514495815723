.termsofuse-content p {
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 25px;
}
.terms-sections p:last-child {
    margin-bottom: 0;
}
.terms-sections {
    padding: 35px 0;
}
.terms-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.05em;
    margin-bottom: 0;
}
.terms-header p {
    font-weight: 400;
}
.terms-gray-sec {
    background: #ddeafc;
}

.terms-sub-header .terms-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 20px;
}
span.terms-content-sticking {
    font-weight: bold;
}
.terms-title span {
    margin-right: 5px;
    display: inline-block;
}
.terms-header {
    margin-bottom: 30px;
}

.terms-contents ol {
    margin: 0;
    padding-left: 15px;
}
.terms-contents ol li {
    line-height: 24px;
    margin-bottom: 20px;
    padding-left: 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #000000;
}
.terms-contents ol li:last-child {
    margin-bottom: 0;
}
@media (max-width:767px){
    .terms-contents ol li {
        margin-bottom: 10px;
        line-height: 22px;
    }
    .terms-sub-header .terms-title {
        font-size: 16px;
        line-height: 22px;
    }
    .terms-title {
        font-size: 20px;
    }
    .termsofuse-content p {
       line-height: 22px;
    }
    .terms-sections {
        padding: 30px 0;
    }
}