.career-content-sec-2 {
    background: #ddeafc;
}
.career-content-sec {
    padding: 80px 0;
}
.career-content-sec-1 .landing-common-description {
    max-width: 82%;
}
.career-content-sec .technology-cont-text p {
    max-width: 80%;
    margin-bottom: 0;
}
.careers-show-box-left, .careers-show-box-right {
margin: 30px 0;
}
.career-main-content{
    margin: 0 auto;
    max-width: 65%;
}
.career-main-content h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 34px;
}
@media (min-width: 768px) and (max-width: 991px) {
    .career-content-sec {
        padding: 50px 0;
    }
    .career-main-content {
        max-width: 90%;
    }
}
@media(max-width:767px){
.career-content-sec {
    padding: 30px 0;
}
.career-content-sec .technology-cont-text p {
    max-width: 100%;
}
.career-main-content {
    max-width: 100%;
}
}