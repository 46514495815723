.footer-left {
    flex: 1;
}
.land-footer-wrap {
    padding: 40px 0 80px;
}
h4.footer-title {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}
.footer-menu-list a {
    text-decoration: none;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #000;
    margin-bottom: 10px;
    display: inline-block;
    border-bottom: 1px solid transparent;
}
.footer-menu-list a:hover, .footer-menu-list a.active {
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
}
.footer-menu-list {
    margin-top: 0;
    margin-bottom: 1rem;
    margin: 0;
    padding: 0;
    list-style: none;
}
.active-class {
    text-decoration: underline;
}
p.copyright-text {
    margin: 12px 0;
}
.social-icons a {
    margin-left: 10px;
}
.social-icons a:first-child {
    margin-left: 0;
}

@media (max-width: 767px) {
    .land-footer-content {
        flex-wrap: wrap;
    }
    .footer-left {
        flex: inherit;
        width: 100%;
    }
    .land-footer-wrap {
        padding: 30px 0;
    }
    .footer-right {
        text-align: center;
        margin-top: 20px;
        border-top: 1px solid #ddd;
        width: auto;
        margin: 20px -15px 0;
        flex: 1;
        padding-top: 20px;
    }
    .land-footer-wrap .container {
        padding: 0 15px;
    }
    .footer-left {
        text-align: center;
    }
}
