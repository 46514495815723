.landing-section-bg-right {
    background-position: right;
    right: 0;
}
.landing-section-bg {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 50%;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    top: 0;
}
.landing-section-bg-left {
    background-position: left;
    left: 0;
}
.bg-over-text {
    position: relative;
    z-index: 2;
}
.landing-common-title {
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 26px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.getresults-content h3 {
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.05em;
    margin-bottom: 25px;
}
.landing-common-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #000000;
}
.landing-section-2 {
    padding: 90px 0;
}
.landing-section-4 {
    padding: 90px 0;   
}
.landing-section-5 {
    padding: 90px 0;  
}
.landing-section-6 {
    padding:90px 0;
    background-color: #DDEAFC; 
}
.landing-section-7 {
    padding: 90px 0 30px;    
}
.landing-section-8 {
    padding: 70px 0;  
}
.land-contact-section {
    background: #DDEAFC;
    padding: 50px 0 60px;
}
.contact-success .modal-dialog{
    max-width: 690px;
}
.contact-success .success-content-wrap h3{
font-weight: 700;
font-size: 26px;
line-height: 32px;
text-align: center;
letter-spacing: 0.05em;
}
.contact-success .success-content-wrap p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
.landing-section-bg {
    background-position: center;
}
.landing-section-2 {
    padding: 50px 0;
}
.landing-section-4 {
    padding: 50px 0;
}
.landing-section-5 {
    padding: 50px 0 30px;

}
.landing-section-7 {
    padding: 50px 0;
}
.landing-section-8 {
    padding: 50px 0;
}
.landing-common-title {
    font-size: 20px;
    line-height: 26px;
 }
 .getresults-content h3 {
    font-size: 20px;
    line-height: 26px;

}
.landing-common-title {
max-width: 100%;
}
}

@media (max-width: 767px) {
    .landing-section-bg {
        position: inherit;
         height: 260px;
         width: 100%;
    }
    .landing-section-2 {
        padding: 30px 0;
    }
    .landing-common-title {
        font-size: 20px;
        line-height: 28px;
        max-width: 100%;
        margin-bottom: 10px;
    }
    .landing-section-4 {
        padding: 30px 0 15px;
    }
    .getresults-content h3 {
        font-size: 20px;
        line-height: 28px;
        text-align: center;

    }
    .landing-common-description {
        text-align: center;
        font-size: 15px;
    }
    .landing-section-5 {
        padding: 30px 0;
    }
    .landing-section-6 {
        padding: 0 0 30px;
    }
    .landing-section-7 {
        padding: 30px 0;
    }
    .contact-success .success-content-wrap h3 {
        font-size: 21px;
        line-height: 24px;
        max-width: 75%;
        margin: 0 auto 20px;
    }
    .contact-success-img img {
        max-width: 70%;
    }
}
