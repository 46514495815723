.nav-tabs .nav-link {
    font-size: 18px;
    background: transparent;
    color: #fff;
    font-weight: 500;
    padding: 5px 15px;
    margin-bottom: 10px;
    border: none;
}
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: transparent;
    border: none;
    position: relative;
    font-weight: 700;
}
.nav-tabs .nav-link.active::after {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    height: 4px;
    content: "";
    bottom: 0;
    border-radius: 20px;
}
.nav-tabs {
    border: none;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: none;
}
.results-tab-wrap .nav-tabs .nav-link {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $theme-body-font-color;
}
.results-tab-wrap .nav-tabs {
    justify-content: space-between;
    margin: 0 -15px;
    position: relative;
    background:#fff ;
    z-index: 99;
    padding-top: 15px;
}
.results-tab-wrap .nav-tabs::after {
    position: absolute;
    bottom: 0;
    height: 2px;
    background: #D7E0E9;
    content: "";
    left: 15px;
    right: 15px;
}
.results-tab-wrap .tab-content {
    padding-top: 25px;
}
.results-tab-wrap .nav-tabs .nav-link {position: relative;z-index: 100;}
.results-tab-wrap .nav-tabs .nav-link.active{
color: $primary-color;

}
.results-tab-wrap .nav-tabs .nav-link.active::after {
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: $primary-color;
    content: "";
    left: 15px;
    right: 15px;
    z-index: 1;
    
}

#FixedTab.sticky .nav-tabs{
    position: sticky;
    top: 0px;

}
.nav-tabs .nav-link:focus {
    outline: none;
}



.faq-tab-wrap .nav-tabs .nav-link {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $theme-body-font-color;
}
.faq-tab-wrap .nav-tabs {
    margin: 0 -15px;
    position: relative;
    background:#fff ;
    z-index: 99;
    padding-top: 15px;
}
.faq-tab-wrap .nav-tabs::after {
    position: absolute;
    bottom: 0;
    height: 2px;
    background: #D7E0E9;
    content: "";
    left: 15px;
    right: 15px;
}
.faq-tab-wrap .tab-content {
    padding-top: 25px;
}
.faq-tab-wrap .nav-tabs .nav-link {position: relative;z-index: 100;}
.faq-tab-wrap .nav-tabs .nav-link.active{
color: $primary-color;

}
.faq-tab-wrap .nav-tabs .nav-link.active::after {
    position: absolute;
    bottom: -10px;
    height: 2px;
    background: $primary-color;
    content: "";
    left: 15px;
    right: 15px;
    z-index: 1;
    
}


@media (min-width: 768px) and (max-width: 991px) { 
    .nav-tabs .nav-link {
        padding: 5px 11px;
    } 
}
@media (max-width: 767px) { 
    .nav-tabs .nav-link {
        font-size: 16px;
    }
    .result-content-area .results-tab-wrap .nav-tabs .nav-link.active::after {
        left: 10px;
        right: 10px;
    }
    .result-content-area .nav-tabs .nav-link {
        font-size: 16px;
        padding: 5px 10px;

    }
   
}