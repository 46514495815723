.members-header-right-search {
    flex: 1;
}
.m-l-10 {
    margin-left: 10px;
}
.no-results-wraper {
    padding: 30px;
    color: #555;
    border-radius: 13px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0px 0px 10px #00000026;
}
span.transport-text {
    background: #EFF0F2;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    line-height: normal;
    color: #474747;
}
.members-wrap .experts-right {
    padding-left: 50px;
}
.members-wrap .experts-pers-info-text {
    word-break: break-all;
}

@media (min-width: 768px) and (max-width: 991px) {
    .expert-left-img img {
        width: 85px;
        height: 85px;
    }
    .experts-box-left {
        padding-left: 10px;
    }
    .members-wrap .experts-right {
        padding-left: 16px;
    }
}