.news-img-sec img {
    width: 100%;
    border-radius: $radius-md;
}
.news-small-left {
    width: 40%;
}
.news-small-left img {
    max-width: 100%;
    border-radius: $radius;
}
.news-small-right {
    width: 60%;
    padding-left: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.news-small-right h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}
.news-small-wrap {
    margin-bottom: 30px;
}
.new-big-sec .sourcebox {
    margin-right: 0;
}
.news-right {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
    height: 100%;
}
.news-right li h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    word-break: break-word;
    
}
.hom-secs .new-big-sec {
    padding-top: 0;
    margin-bottom: 40px;
}
.news-right ul {
    margin: 0;
    padding: 0;
}
.news-right li {
    list-style: none;
    min-height: 100px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #d9d9d9;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}
.news-right li:last-child {
    border: none;
    padding-bottom: 0;
}
.news-right li .authors-name {
    margin-top: 12px;
}
.news-small-box-wrap {
    padding-right: 10px;
}
.hom-secs .news-wrap .sourcebox h3,
.hom-secs .new-big-sec {
    border: none;
}
.hom-secs .news-wrap .sourcebox h3 {
    height: auto;
    word-break: break-word;
}
.new-right-wrap {margin-left: -4px;}
.news-wrap .source-content {
    min-height: inherit;
}
.hom-secs .new-big-sec {
    padding-right: 12px;
}
@media (min-width: 1200px) {
    .news-big-repeat:nth-child(5) .new-big-sec, .news-big-repeat:nth-child(6) .new-big-sec {
        margin-bottom: 0;
    }
    .news-wrap {
        padding-bottom: 40px;
    }
}
@media (max-width: 1400px) {
    // .news-small-wrap {
    //     // margin-bottom: 25px;
    // }
}
@media (max-width: 991px) {
    .news-small-box-wrap.right-border {
        border: none;
    }
    .news-right li {
        padding: 20px 10px;
        min-height: inherit;
        border: 1px solid #d9d9d9 !important;
        margin-bottom: -1px;
    }
    .news-right {
        padding-left: 0;
        border: none;
    }
    .news-right li h4 {
        font-size: 14px;
    } 
    .new-right-wrap {
        margin-left: 0;
        padding-left: 13px !important;
    }
    .hom-secs .new-big-sec {
        padding-right: 0;
    }
    li.news-right-shimmer-box {
        display: none;
    } 
}
@media (max-width: 767px) {
    .new-big-sec {
        margin-bottom: 30px;
    }
    .news-small-left {
        width: 100px;
    }
    .news-small-right {
        width: auto;
    }
  
   
}
