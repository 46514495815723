.relevent-box h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
}
.relevent-box p {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    margin: 0;
}
.relevent-box {
    width: 25%;
    padding: 0 11px;
}
.relevent-box-content {
    background: #fff;
    box-shadow: 0px 10px 20px rgb(155 163 171 / 25%);
    padding: 40px 20px;
    height: 100%; border-radius:10px;
}
.find-relevent-rwo{
    padding: 0 50px;
    margin-top: 55px;
}
.landing-section-4 .landing-common-description {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
}
.landing-section-4 .landing-common-title {
    max-width: 85%;
}
@media (min-width: 768px) and (max-width: 991px) {
.relevent-box {
    width: 50%;
    margin-bottom: 20px;
}
.find-relevent-rwo {
    flex-wrap: wrap;
}
.relevent-box-content {
    box-shadow: 0px 0px 5px rgb(155 163 171 / 25%);

}
}

@media (max-width: 767px) {
.relevent-box {
    width: 100%;
    margin-bottom: 20px;
}
.find-relevent-rwo {
    padding: 0;
    margin-top: 30px;
    flex-wrap: wrap;
}
.relevent-box-content {
    box-shadow: 0px 0px 5px rgb(155 163 171 / 25%);
    padding:20px 30px;
    height: auto;
    background-color: #eaeff7;

}
.relevent-box h4 {
    font-size: 16px;
    margin-bottom: 15px;
}
.landing-section-4 .landing-common-description {
    max-width: 90%;
}
.technology-show-box-left, .technology-show-box-right {
    margin-top: 10px;
    margin-bottom: 10px;
}

}