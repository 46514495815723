.pagenot-found .whitebox {
    height: calc(100vh - 305px);
    min-height: 450px;
}
.error-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
}
.errorpages-content p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #5b5b5b;
}
.positioned-backarrow {
    position: absolute;
    left: 30px;
    top: 30px;
}
.link-expired p {
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
}
