.header-top {
    background: $primary-color;
    padding: 12px;
}
.menu-items a {
    padding: 0 5px;
    margin: 0 20px;
    color: #5b5b5b;
    text-decoration: none;
    font-size: 14px;
}
.logo {
    margin: 0;
    font-size: 13px;
}
.header-content {
    padding:14px 9px;
}
.profile-icon img {
    margin-right: 10px;
}
.menu-items a:last-child {
    margin-right: 0;
    padding-right: 0;
}
.menu-items .dropdown .btn {
    min-width: inherit;
    padding: 0;
    background: transparent;
    border: none;
    color: #5b5b5b;
}
.menu-items .dropdown .dropdown-toggle:focus {
    box-shadow: none;
}
.menu-items .dropdown a {
    margin: 0;
}
.menu-items .dropdown-menu.show.dropdown-menu-end {
    border: none;
    box-shadow: 0 1px 4px #c1c1c1;
    padding: 10px;
}
.menu-items .dropdown-item {
    font-size: 13px;
    line-height: 24px;
}
.menu-items .dropdown a {
    font-size: 13px;
    line-height: 24px;
}
.menu-items .dropdown-item:focus,
.menu-items .dropdown-item:hover {
    color: $primary-color;
    background-color: transparent;
}
.menu-items .dropdown-menu {
    min-width: 120px;
}
.menu-items a:hover{
    color: $primary-color;
    text-shadow: 0px 0px 1px $primary-color;   
}
.menu-items a.active {
    color: $primary-color;
    text-shadow: none;
    font-weight: 600;
}
.header-wrap {
    box-shadow: 0px 2px 4px rgb(189 189 189 / 25%);
}


.user-img {
    display: inline-block;
    margin-right: 3px;
}
.aichat-icon {
    position: absolute;
    top: -20px;
    right: -6px;
    @media (max-width: 991px) {
        top: -14px;
        right: -22px;
    }
}
@media (min-width: 768px) {
    .mobile-menu {
        display: none !important;
    }
    .mobile-menu-only {
        display: none !important;
    }
    .notification-mobile-only {
        display: none !important;
    }
   .mobile-menu-buttons-wrap.d-flex {
display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .menu-items a {
        margin: 0 6px;
        font-size: 13px;
    }
    .logo img {
        max-height: 26px;
    }
}
@media (max-width: 767px) {
    .mobile-menu {
        display: block;
    }
    .menu-items .notification-dropdown {
        display: none;
    }
    .mobilemenu-hidden {
        display: none;
    }
    .mobilemenu-active {
        position: absolute;
        top: 40px;
        right: -20px;
        left: -20px;
        background: #fff;
        z-index: 100;
        // padding: 20px 0;
    }
    .menu-section-wrap {
        position: relative;
    }
    .menu-items {
        flex-wrap: wrap;
    }
    .menu-items a {
        width: 100%;
        line-height: 34px;
    }
    .mobile-menu-only {
        display: block;
    }
    .mobilemenu-active .dropdown {
        padding: 0 20px;
        border-top: 1px solid #ddd;
        width: 100%;
        border-bottom: 1px solid #ddd;
        padding: 10px 20px;
    }
    .menu-items .dropdown a {
        font-size: 14px;
        line-height: 34px;
    }
    .menu-items .dropdown-menu {
        min-width: 120px;
        position: inherit !important;
        transform: none !important;
    }
    .menu-items .dropdown-menu {
        position: inherit !important;
        transform: none !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
    }
    .mobile-menu {
        background: none;
        border: none;
        box-shadow: none;
    }
    .mobile-menu img {
        max-height: 30px;
    }
    .header-content {
        padding: 9px 0;
    }
    .header-top .double-btn .btn {
        margin-left: 0;
        width: 48%;
    }
    .header-top {
        padding-left: 0;
        padding-right: 0;
    }
    .user-img {
        display: inline-block;
        margin-right: 10px;
    }
    .menu-items .dropdown .btn {
        color: #000;
        font-weight: 600;
    }
    .menu-items .dropdown .dropdown-toggle::after {
        margin-left: 7px;
    }
    .notification-mobile-only .notification-dropdown .btn {
        margin-right: 25px;
        position: relative;
        min-width: 30px;
        padding: 0;
        background: transparent;
        border: none;
        height: auto;
        
    }
    .notification-mobile-only .btn svg {
        top: -1px;
    }
 
}
