.fin-result-contents {
    padding-left: 15px;
}
.fin-result-contents .sourcebox h3 {
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
    word-break: break-word;
}
.fin-result-contents .sourcebox {
    margin-bottom: 50px;
}
.fin-result-contents .sourcebox p {
    margin-bottom: 25px;
}
.fin-result-contents .sourcebox {
    border: none;
    margin-right: 0;
    padding-right: 0;
}
.more-text-wrap {
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 22px;
}
.showmore-button {
    white-space: nowrap;
    font-weight: 500;
    font-style: italic;
    color:$primary-color;
    cursor: pointer;
}
.fin-result-contents .source-content {
    min-height: inherit;
}
.multi-label-wrap .source-label {
    cursor: default !important;
}

// .source-label:empty {
//     display: none;
// }
@media (max-width:991px){
    .fin-result-contents {
        padding-left: 0;
    }
}
@media (max-width:767px){
    .more-text-wrap {
        margin-bottom: 10px;
    }
    .fin-result-contents .sourcebox h3 {
        font-size: 15px;
    }
  
 
}