.result-filter-box {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 10%);
    border-radius:$curveradius;
    padding: 18px 18px 5px;
}
.result-filter-box .accordion-item {
    border: none;
}
.result-filter-box .accordion-button {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $theme-body-font-color;
    background: transparent;
    padding-left: 10px;
    padding-right: 10px;
}
.result-filter-box .accordion-button:not(.collapsed) {
    color: $theme-body-font-color;
}
.result-filter-box .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.result-filter-box .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
}
.result-filter-box .accordion-header {
    margin-bottom: 0;
    border-bottom: 1px solid #d7e0e9;
}
.result-filter-box .accordion-item:last-child .accordion-header {
    border: none;
}
.result-filter-box .accordion-button:not(.collapsed) {
    box-shadow: none;
}
.result-filter-box h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #5b5b5b;
    border-bottom: 1px solid #d7e0e9;
    padding-bottom: 15px;
    margin-bottom: 5px;
    padding-left: 10px;
    text-indent: 2px;
}
.filter-sub-text {
    font-size: 11px;
    font-weight: normal;
    display: inline-block;
    margin-left: 2px;
    text-indent: 0;

}

.result-filter-box .accordion-button::after {
    width: 18px;
    height: 18px;
    background-size: 18px;
}
.result-filter-box .accordion-body {
    padding: 10px;
}
.country-header-search {
    margin: 0 -10px;
    position: relative;
}
.country-header-search .country-search {
    padding-left: 30px;
}
span.search-icon {
    position: absolute;
    left: 7px;
    top: 9px;
}
.view-more-check {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: $primary-color;
}

.calendar-icon {
    background: $primary-color;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    cursor: pointer;
    pointer-events: none;
}
.calendar-modal .modal-dialog {
    max-width: 621px;
}
.calendar-modal .modal-dialog.modal-md {
    max-width: 500px;
}
.calendar-pop-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    border-bottom: 1px solid #cccdce;
    margin-bottom: 12px;
    padding-bottom: 10px;
}
.calendaer-pop-footer {
    margin-top: 10px;
    border-top: 1px solid #d7e0e9;
}
.filter-accordian-wrap .btn-wrap {
    // padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}
// ****checkbox-style start****
.checkbox-wrapper {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
}

.checkbox-wrapper:last-child {
    margin-bottom: 0;
}

.checkbox-wrapper input {
    position: absolute;
    left: -9999px;
}

.checkbox-wrapper input + label {
    cursor: pointer;
    user-select: none;
}

.checkbox-wrapper input + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #cccdce;
    border-radius: 1px;
}

.checkbox-wrapper input:checked + label:before {
    background: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/4QBMRXhpZgAATU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAAKgAAAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/8AAEQgAKgAqAwEiAAIRAQMRAf/EAB8AAAEFAQEBAQEBAAAAAAAAAAABAgMEBQYHCAkKC//EALUQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+v/EAB8BAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKC//EALURAAIBAgQEAwQHBQQEAAECdwABAgMRBAUhMQYSQVEHYXETIjKBCBRCkaGxwQkjM1LwFWJy0QoWJDThJfEXGBkaJicoKSo1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoKDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uLj5OXm5+jp6vLz9PX29/j5+v/bAEMAAQEBAQEBAgEBAgMCAgIDBAMDAwMEBQQEBAQEBQYFBQUFBQUGBgYGBgYGBgcHBwcHBwgICAgICQkJCQkJCQkJCf/bAEMBAQEBAgICBAICBAkGBQYJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCf/dAAQAA//aAAwDAQACEQMRAD8A/v4ooooAKKK8S+Lv7R/wO+A11pVj8XfElroc2ty+TZxzb2aQ5ALERqxSMEgNI+1Fzywruy7LMTjKyw+EpynN7Rim27auyV3otThzLNMNg6LxGMqRhBbyk1FK+iu3ZavQ9tooorhO4//Q/v4r83fi5/wUj8A/Av8Aatj/AGcvifodzpelSQ27/wDCRSygQB7lAyt5OzJt1J8t5hJ8rqwKYUmv0ir5A/bI/Y6+H37X/wAOW8NeIQtjrlirvpOqquZLaUjlW6F4XIAkTPPDDDAGvvfDetkMcyVPiSnKVCaceaLadNu1qiS+Ll/ld1Z3s7JP4HxJoZ/LLHV4aqRjiINS5ZJNVEr3ptv4ebpJWaatdJtqp+2D+2l8NP2S/hqvivVZY9U1nVIz/Y+mxSDddNjiQsM7YFyC8mPZcsQK/KT9jz9kH4m/tsfEz/hsv9sV5LjSbiRZtM06UFFvFQ5iAjP+rsY/4E6zck5Ukve/ZL/4JP8AxLvfiRH4v/bMdbvSfDQS10zTPtYvEvEgJ8rcwZvLs06rCdrMThkVch/6EoIILWBLW1RY4o1CoigBVUDAAA4AA6Cv2bPuKcn4KwVTJ+E66r4qqrVMStowe1Olq7Nq3NJPfre3J+LZBwrnHG+Np5xxbQdDC0nelhnvKa3qVdFdJ35YtbdLN88tFFFfy4f1Of/R/v4ooooAKKKKACiiigD/2Q==");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center;
}
// ****checkbox-style end****

// ***calendar-style***
.custom-date-range .rdrDefinedRangesWrapper {
    display: none;
}
// .common-modal .modal-dialog .modal-content {
//     padding: 10px;
// }
.custom-date-range .rdrMonth {
    width: 100%;
}
.custom-date-range .rdrCalendarWrapper,
.custom-date-range .rdrDateRangePickerWrapper {
    width: 100%;
}

.custom-date-range .rdrDateRangePickerWrapper {
    display: flex;
}
.custom-date-range .rdrDayNumber {
    font-weight: 500;
}
.custom-date-range .rdrWeekDay {
    color: #000;
    font-weight: bold;
}
.custom-date-range .rdrMonthName {
    text-align: left;
    font-weight: 600;
    color: #5b5b5b;
    padding: 0.833em;
    background: #f3f5f7;
}
.custom-date-range .rdrInRange {
    color: transparent !important;
}
.custom-date-range .rdrDateDisplayItemActive input,
.custom-date-range .rdrDateDisplayItem input {
    color: #000;
    font-weight: 500;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #000 !important;
}
.custom-date-range .rdrEndEdge,
.custom-date-range .rdrStartEdge {
    border-radius: 30px;
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    border: none !important;
}

.custom-date-picker .react-date-picker__wrapper {
    background-color: #f3f5f7;
    border-radius: $radius;
    height: 39px;
    border: none;
    font-size: $body-font-size-medium;
    width: 100%;
    padding-left: 10px;
}
.custom-date-picker .react-date-picker {
    display: block;
}
.custom-date-picker .react-date-picker__button svg {
    width: 15px;
    margin-right: 6px;
}

.custom-date-picker .react-date-picker__calendar .react-calendar {
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
    border-radius: 3px;
}
.custom-date-picker .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 20px;
}
.custom-date-picker .react-calendar__navigation {
    margin-bottom: 0;
}
.custom-date-picker .react-calendar__navigation__label {
    font-size: 14px !important;
    font-weight: bold;
    color: $primary-color;
}
.custom-date-picker .react-calendar__tile {
    font-size: 11px;
    font-weight: 600;
    font-family: $primary-font;
}
.custom-date-picker .react-calendar__tile--active {
    background: $primary-color;
    border-radius: $radius;
}
.custom-date-picker .react-calendar button:enabled:hover {
    border-radius: $radius;
}
.custom-date-picker .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    background: #ebf0f8;
    padding: 5px 0;
    margin-bottom: 10px;
}
.custom-date-picker .react-calendar__month-view__weekdays abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
}
.custom-date-picker .react-date-picker__calendar {
    width: 300px;
    z-index: 50;
}
.custom-date-picker .react-date-picker__inputGroup__input:invalid {
    background: transparent;
}
.custom-date-picker .react-date-picker__inputGroup__input:focus {
    border: none;
    outline: none;
}
// ***calendar-style end***
.mobile-fliter {
    display: none;
}
.filter-toggle-button-box h4 {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}
.filter-toggle-button-box h4 img {
        max-width: 16px;
        margin-left: 10px;

}
.filter-toggle-button-box {
    padding: 20px;
    background: #eff0f2;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 0 5px #c0c0c0;
}
.filter-toggle-button-box h4 {
    margin-bottom: 0;
}
.form-switch .form-check-input {
    width: 40px !important;
    height: 20px;
    border-color: #DFE1E7;
}
.form-switch  .form-check-input:focus {
    box-shadow: none;
}
.experts-filter-head {
    position: relative;
}
.text-info-head {
    position: absolute;
    right: 0;
}
.experts-filter-head h4 {
    flex: 1;
}
.text-info-head img {
    max-width: 16px;
}
.react-date-picker__inputGroup__input, .react-date-picker__inputGroup {
    cursor: pointer;
}
.country-filter .react-multi-select {margin-bottom: 10px;}
.country-filter .react-select-2-option__menu {z-index: 10;}
@media (min-width: 768px) and (max-width: 991px) {
    .result-filter-box .accordion-body {
        padding: 10px 0;
    }
    .result-filter-box .accordion-button {
        font-size: 13px;
        padding-left: 0;
        padding-right: 0;
    }
}
@media (max-width: 991px) {
    .multi-label-wrap {
        flex-wrap: wrap;
        margin-bottom: 5px;
    }
    .source-label {
        margin-bottom: 10px;
        margin-left: 0 !important;
        margin-right: 10px;
    }
    .result-filter-box {
        margin-bottom: 20px;
    }
   
    .source-label:empty {
        display: none;
    }
    
}
@media (max-width: 767px) {
    .filter-active {
        display: block;
    }
    // .mobile-fliter {
    //     display: flex;
    // }
   
    .mobile-fliter svg path {
        fill: #fff;
    }
    .filter-active .result-filter-box {
        margin-bottom: 20px;
        position: fixed;
        bottom: 70px;
        z-index: 110;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0;
    }

    .mobile-fliter-full {
        display: flex;
        justify-content: space-between;
        background: $primary-color;
        position: fixed;
        z-index: 130;
        left: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        padding:10px 15px 10px 10px;
        align-items: center;
        box-shadow: 1px 0 6px #0000009e;
    }
    .mobile-fliter-full h4 {
        font-size: 14px;
        margin: 0;
        color: #fff;
    }
    .mobile-fliter-full svg path{fill: #fff;}
    .filter-hidden {
        display: none;
    }
    .econ-fixed-div .filter-active {
        position: fixed;
        background: #fff;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .econ-fixed-div .filter-active .result-filter-box {
        position: relative;
        top: inherit;
    }
    .filter-toggle-button-box {
        margin-bottom: 0;
    }
}