.custom-accordian .accordion-item {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    padding: 25px 0;
}
.custom-accordian .accordion-button {
    box-shadow: none;
    background: transparent;
    font-size: 16px;
    padding: 0;
}
.custom-accordian .accordion-button:not(.collapsed) {
    color: $primary-color;
    background: transparent;
    padding: 0;
    font-size: 16px;
}
.custom-accordian .custom-accordian-header {
    display: flex;
    align-items: flex-start;
}
.custom-accordian h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}
.faq-index {
    margin-right: 0;
    font-weight: bold;
    min-width: 25px;
}
.custom-accordian .accordion-button:focus {
    box-shadow: none;
}
.custom-accordian .accordion {
    padding: 0 25px;
}
.custom-accordian .accordion-body {
    padding: 0 25px;
    margin-top: 15px;
}

.custom-accordian .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.custom-accordian .accordion-item:last-of-type {
    border-radius: 0;
}
.filter-accordian-wrap .accordion-button:hover {
    z-index: 0;
}
@media (max-width: 767px) {
    .custom-accordian .accordion {
        padding: 0;
    }
    .custom-accordian h4 {
        font-size: 14px;
        line-height: 20px;
        padding-right: 5px;
    }
  
    .custom-accordian .accordion-item {
        padding: 15px 0;
    }
  
}
