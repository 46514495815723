.results-header .btn {
    padding: 0 10px;
}
.results-header {
    border-bottom: 1px solid #d7e0e9;
    padding-bottom: 15px;
}
.results-header h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin: 0;
}
.results-text {
    font-size: 18px;
    line-height: 22px;
    color: #252525;
    margin-bottom: 10px;
}
.savequery-wrap {
    margin: 30px 0;
}
.mobile-fliter-full {display: none;}
.results-btns.double-btn {
    min-width: 372px;
    margin-left: 10px;
    margin-top: 3px;
}
.filter-button .btn {
    padding: 0 10px;
    min-width: inherit;
}
.filter-box-scroll {
    max-height: 25vh;
    margin-top: 10px;
}
.results-tab-wrap .nav-link:focus-visible {outline: none !important;}
.result-content-area .results-tab-wrap .nav-item:nth-child(8) {
    position: relative;
}
.result-content-area .results-tab-wrap .nav-item:first-child {
  text-align: left;
}
.result-content-area .results-tab-wrap .nav-item:nth-child(8)::after {
    position: absolute;
    left: 0;
    width: 2px;
    background-color: #ddd;
    top: -6px;
    bottom: 6px;
    content: "";
}

.result-content-area .results-tab-wrap .nav-tabs .nav-link {
    display: inline-block;
}
.filter-box-scroll .ps__thumb-y {
    width: 4px;
}
.filter-box-scroll .ps__rail-y {
    width:6px;
}
.ps__rail-y:hover > .ps__thumb-y {
    background-color: #999;
    width: 4px;
}
@media (min-width: 768px) {
    .result-content-area .results-tab-wrap .nav-item {
        flex:1;
        text-align: center;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .filter-button .btn {
        padding: 0 10px;
        min-width: 100%;
        margin: 10px 0 0 0;
    }   
 
}

@media (max-width: 991px) {
    .results-tab-wrap .nav-tabs{
        justify-content: flex-start;
    }
    .results-tab-wrap .nav-tabs .nav-link.active::after {
        bottom: -3px;
    }
    .results-tab-wrap .nav-tabs .nav-link {
        font-size: 13px;
        margin-bottom: 15px;
    }
    .results-tab-wrap {
        margin: 0;
    }
  
}


@media (max-width: 767px) {
    .results-header {
        flex-wrap: wrap;
    }
    .results-header h4 {
        margin-bottom: 15px;
        word-break: break-all;
    }
    .results-header .btn {
        padding: 0 5px;
        font-size: 12px;
        min-width: inherit;
    }
    .results-btns.double-btn {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .results-text {
        font-size: 15px;
    }
    .results-btns.double-btn {
        min-width:inherit;
        margin-left:0;
        margin-top: 0;
    }
    .main-container {
        position: inherit !important;
    }
    .result-content-area .results-tab-wrap .nav-item:nth-child(8)::after {
        top: 0px;
        bottom: 15px;
    }
  

}
