.datatable-wrap .rdt_TableRow {
    min-height: 60px;
    border-color: #e9e9e9;
}
.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child,
.checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child {
    min-width: 30px;
    flex: 0 0 30px;
    position: relative;
}
.datatable-wrap .rdt_TableHeadRow {
    background: #e6e9ec;
    font-weight: 600;
    font-size: 15px;
    border: none;
}
.datatable-wrap .rdt_TableCell {
    font-size: 13px;
    color: #000;
    flex-wrap: wrap;
}
.share-icon-cell img {
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.datatable-wrap .rdt_TableCell a {
    text-decoration: none;
    color: $primary-color;
}
.datatable-hedaer-right {
    flex: 1;
}
// .datatable-hedaer-left.d-flex {
//     width: 45%;
// }
.table-buttons-wrap {
    // margin-left: 100px;
    padding-right: 150px;
}

.search-svg-wrap {
    position: absolute;
    left: 10px;
    top: 9px;
    pointer-events: none;
}
.search-input-wrap .input-item {
    padding-left: 30px;
}
.checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"] {
    width: 16px !important;
    height: 16px !important;
    margin-left: 3px;
}
.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"]::after
// .checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"]::after 
{
    width: 18px;
    height: 18px;
    content: "";
    position: absolute;
    top: -3px;
    left: -1px;
    border: 2px solid #cccdce;
    pointer-events: none;
    background: #fff;
}

.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"], 
.checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"] {
    position: relative;
}

.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"]:checked:before
// .checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"]:checked:before
{
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: -3px;
    left: -1px;
    background-color: $primary-color;
    border-radius: 1px;
}
.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"]:checked:after
// .checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"]:checked:after
 {
    content: "";
    display: block;
    width: 7px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: -1px;
    left: 5px;
    background: transparent;
}
.selectall-wrap {
    display: none;
}
@media(max-width:992px){
    .table-buttons-wrap {
        padding-right: 30px;
    }
}
@media(max-width:767px){
.datatable-header-area{
    flex-wrap: wrap;
}
.datatable-hedaer-right {
    width: 100%;
    flex: auto;
}
.table-buttons-wrap {
    margin-left: 0;
    padding-right: 0px;
}
.datatable-hedaer-left {
    margin-bottom: 15px;
    justify-content: flex-end;
    width: 100%;
}
.results-tab-wrap .tab-content {
    padding-top: 15px;
}
.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"]::after
// .checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"]::after 
{
    top: -2px;
}
.checkbox-table.datatable-wrap .rdt_TableRow .rdt_TableCell:first-child input[type="checkbox"]:checked:before
// .checkbox-table.datatable-wrap .rdt_TableHeadRow .rdt_TableCol:first-child input[type="checkbox"]:checked:before
{
    top: -2px;

}

}