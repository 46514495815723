.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    font-size: 28px;
    line-height: 31px;
}
body .owl-carousel .owl-nav button.owl-prev,
body .owl-carousel .owl-nav button.owl-next {
    background: #ffffff;
    border: none;
    box-shadow: 0 1px 4px #c1c1c1;
    border-radius: 4px;
    width: 29px;
    height: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.owl-theme.financing-slider .owl-nav {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
}
body .owl-carousel .owl-nav button.owl-prev span,
body .owl-carousel .owl-nav button.owl-next span {
    height: 20px;
    line-height: 20px;
    position: relative;
    top: -3px;
}

@media (max-width: 991px) {
.owl-theme.financing-slider .owl-nav {
    justify-content: center;

}
}