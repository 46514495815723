.footer-content {
    border-top: 1px solid #e3e3e3;
    padding: 30px;
    margin: 0;
    

}
.footer-content p {
    font-size: 11px;
    line-height: 18px;
    color: #5b5b5b;
}
