// General variables
$theme-body-font-color: #000000;
$body-color: #F6F7F9;

// Theme colors variables
$primary-color: #1A73E8;
$secondary-color: #CE002A;
$auth-primary-color:#F69525;
$success-color: #17D664;
$info-color : #a927f9;
$warning-color: #f8d62b;
$danger-color: #EC1F1F;
$light-color : #f4f4f4;
$light-gray : #ecf3fa;
$light-semi-gray :#efefef;
$dark-gray: #898989;
$dark-color : #2c323f;
$gray-60 :#cccccc;
$card-body-color : #f9f9fa;
$transparent-color: transparent;
$auth-bg-color:#fafafa;
$light : #fdfeff;
$semi-dark :#59667a;
$light-text: #999999;
$light-background: #d7e2e9;
$light-font: rgba(27, 49, 85, 0.4);
$light-semi-font: $semi-dark;
$light-chartist-grid-color: #f6f9fd;
$pitch-color: #fe8a7d;
$color-scarpa: #4c5667;
$color-fiord: #455a64;
$black : #000;
$white:#fff;
$table-head-bg:#e8eaec;
$green-text:#0EC60E;
$circulargraph-blue : #0d6efd;
$circulargraph-green : #3DD598;
$circulargraph-yellow : #FF974A;
$graph-primary:#3DD598;
$graph-secondary:#0062FF;
$graph-c:#6200ff;
$dbd-primary-graph:#FFE223;
$dbd-graph-secondary:#11E1A3;

// gradient colors
$gradient-primary: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);

//social colors
$fb: #50598e;
$twitter: #6fa2d8;
$google-plus: #c64e40;
$linkedin: #0077B5;

//radius
$radius:3px;
$curveradius:15px;
$radius-md:10px;

//fonts
$primary-font:'Montserrat', sans-serif;
$font-serif: sans-serif;
$body-font-size:13px;
$body-font-size-medium:12px;


