.shimmer-card {
    position: relative;
    -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 6px;
    height: 240px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 15px;

    .shimmerBG {
        animation-duration: 2.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: #ddd;
        background: linear-gradient(to right, #f2f2f2 8%, #fcfcfc 18%, #f2f2f2 33%);
        background-size: 1200px 100%;
    }

    @-webkit-keyframes shimmer {
        0% {
            background-position: -100% 0;
        }
        100% {
            background-position: 100% 0;
        }
    }

    @keyframes shimmer {
        0% {
            background-position: -1200px 0;
        }
        100% {
            background-position: 1200px 0;
        }
    }

    .media {
        height: 200px;
    }

    .p-32 {
        padding: 32px;
    }

    .title-line {
        height: 24px;
        width: 100%;
        margin-bottom: 12px;
        border-radius: 20px;
    }

    .content-line {
        height: 8px;
        width: 100%;
        margin-bottom: 16px;
        border-radius: 8px;
    }

    .end {
        width: 40%;
    }
}

.m-t-24 {
    margin-top: 24px;
}
.no-other-reco {
    font-size: 13px;
    color: #727780;
    padding: 0 15px;
    display: block;
    line-height: 34px;
}
.shimmer-card .big-title-line.title-line {
    height: 34px;
}
.shimmerloadingtext .shimmerBG {
animation: none;
}
.shimmerloadingtext .shimmer-card .shimmerBG {
background: #e4e7ea;
}
// .multimedia-wrap .shimmerloadingtext .shimmer-card {
//     border: none;
// }
.shimmerloadingtext .shimmer-card {
border-left: 1px solid #e4e7ea;
box-shadow: none;
border-radius: 0;
    margin: 0;
    height: auto;
}
.shimmerloadingtext .shimmer-card .p-32 {
    padding:0 0px 32px 20px;
}
.news-right-shimmer-box .shimmer-card {
height: 140px;
margin-bottom: 0;
}
.news-right-shimmer-box .shimmer-loading-box {
    width: 100%;
}
.multimedia-shimmer-loading-box .shimmer-card .content-line{
margin-bottom: 25px;
}
.multimedia-shimmer-loading-box {
    height: 100%;
    padding-bottom: 40px;
}
.multimedia-shimmer-loading-box .shimmer-card {
    height: 100%;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .shimmerloadingtext .shimmer-card .p-32 {
        padding:0 0px 32px 20px;
    }
}
@media (max-width: 991px) {
    .rec-shimmer-row .shimmer-col-3 {
        display: none;
    }
    .no-other-reco {
        font-size: 13px;
    }
    .shimmerloadingtext-2 {
        display: none;
    }
    .shimmerloadingtext-single {
        display: none;
    }
}
@media (max-width: 767px) {
    .rec-shimmer-row .shimmer-col-2 {
        display: none;
    }
    .shimmerloadingtext {
        display: none;
    }
}
