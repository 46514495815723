.innerwrapper.profile-wrap {
    position: relative;
}
.innerwrapper.profile-wrap::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 355px;
    right: 0;
    background: #f3f5f7;
    content: "";
    pointer-events: none;
    z-index: -1;
}
.prof-left {
    padding: 0 15px;
    width: 28%;
}
.prof-right {
    padding: 0 15px;
    width: 72%;
}
.profile-quick-details h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
}

@media(max-width:991px){
    .prof-left {
        width: 100%;
        margin-bottom: 20px;
    }
    .prof-right {
        width: 100%;
    } 
   
}
@media(max-width:767px){
    .members-wrap .inner-title {
        margin-bottom: 15px !important;
    }
}