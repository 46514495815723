.chat-sidebar-logo-box {
    background-size: cover;
    margin: 0 -24px 20px;
     background: transparent !important;
    padding: 24px;
    color: #000;
    border-bottom:1px solid #E9E9E9
}
.chat-sidebar-logo-box h3 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    margin-bottom: 5px;
}
.chat-sidebar-search-wrap {
    margin-top: 24px;
    margin-bottom: 30px;
}
@media (max-height: 800px) {
    .chat-sidebar-logo-box {
        margin-bottom: 20px;
    }
    .chat-sidebar-search-wrap {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .chat-sidebar-logo-box {
        padding: 18px 24px;
    }
}
@media (max-height: 760px) {
    .chat-sidebar-logo-box {
        margin-bottom: 12px;
    }
    .chat-sidebar-search-wrap {
        margin-top: 15px;
        margin-bottom:15px;
    }
    .chat-sidebar-logo-box {
        padding: 10px 24px;
    }
    .chat-sidebar-logo-box h3 {
        margin-bottom: 0;
    }
  
}

@media (max-width:1200px){
    .chat-sidebar-logo-box {
        margin: 0 -10px 20px;
        padding: 10px;
    }
    
}
@media (max-width:991px){
    
}