.chat-textarea-wrap {
  position: absolute;
  bottom: 25px;
  left: 10px;
  right: 10px;
  padding-top: 50px;
  padding-bottom: 25px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 -14px 13px rgba(255, 255, 255, 0.75);

  @media (min-width:991px) and (max-width:1200px) {
    left: 18px;
    right: 23px;

  }

  @media (max-height:800px)  {
    bottom:6px;
  }
}

.chat-textarea-form {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 20px 40px 5px #EBEEF1;
  border-radius: 12px;
  padding: 18px 55px 18px 25px;
  position: relative;

}

.chat-textarea-wrap .textarea-item {
  border: none;
  width: 100%;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  max-height: 120px;

  @media(max-height:768px) {
    max-height: 100px;
  }

  @media(max-height:750px) {
    max-height: 70px;
    font-size: 13px;
    line-height: 18px;
  }
}

.chat-textarea-wrap .textarea-item:focus-visible,
.chat-textarea-wrap .textarea-item:focus {
  outline: none !important;
}

.chat-textarea-wrap .chat-textarea-form-outer {
  margin: 0 auto;
  position: relative;
  max-width: 850px;
}

.chat-textarea-wrap .send-btn {
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 5px;
  top: 0px;
  border: none;
  text-indent: -9999px;
  width: 50px;
  height: 60px;
  padding: 0;
  background-color: transparent;
}

.chat-textarea-wrap .send-btn:disabled {
  opacity: 0.5;
}

.chat-select-box select.form-select {
  height: 24px;
  font-size: 13px;
  padding: 0 8px;
  width: fit-content;
  font-weight: 500;
  border: none;
  background: #fff;
  cursor: pointer;
  padding-right: 30px;
}

.chat-select-box {
  border-radius: 3px;
  background: linear-gradient(180deg, #4991ef 0%, #1a73e8 100%);
  box-shadow: 0px 10px 10px 0px rgba(133, 175, 214, 0.45);
  padding: 8px 10px;
  margin-bottom: 0;
}

.chat-forms-btns-wrap {
  margin-bottom: 8px;
}

.select-label-text {
  margin-right: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.new-chat-btn {
  font-weight: 600;
  font-size: 13px;
  color: #333;
  text-decoration: none;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 8px 20px #EAEAEA;
  border-radius: 12px;
  width: 62px;
  white-space: nowrap;
  height: 62px;
  overflow: hidden;
  text-align: center;
  align-items: center;
  padding: 0 10px 0 17px;
  transition: width 0.3s;
  display: flex;
  align-items: center;
  margin-right: 6px;

  .new-chat-btn-text {
    opacity: 0;
  }

  @media (max-width:767px) {
    width: 52px;
    padding-left: 12px;
    height: 52px;
  }

}

.new-chat-btn:hover {
  width: 136px;

  @media (max-width:767px) {
    width: 52px;
  }

  transition: width 0.3s;

  .new-chat-btn-text {
    opacity: 1;
  }
}

.new-chat-btn:hover span.new-chat-btn-img {
  rotate: 360deg;
}

span.new-chat-btn-img {
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}

.select-arrow {
  position: absolute;
  right: 6px;
  top: -1px;
  cursor: pointer;
  height: 25px;
  pointer-events: none;
}

.chat-textarea-form .textarea-item::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.generate-loading span {
  margin-left: 15px;
}

.dot-elastic {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dot-elastic 1s infinite linear;
  margin-right: 15px;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -8px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dot-elastic-before 1s infinite linear;
}

.dot-elastic::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $primary-color;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.7);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.7);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.7);
  }

  100% {
    transform: scale(1, 1);
  }
}

// .generate-loading {
//   position: absolute;
//   right: 0px;
//   top: -40px;
//   color: #000;
//   font-weight: 500;
//   margin-right: 0px;
//   background: #def;
//   padding: 5px 20px;
//   border-radius: 6px;
//   border: 1px solid #9cbcdc;
// }

.generate-loading-anim {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #f1f6fc;
  background: linear-gradient(to right,
      #ecf1f8 8%,
      #f9fcff 18%,
      #eff6ff 33%);
  background-size: 1200px 100%;
}

.text-length-show {
  height: auto;
  line-height: 24px;
  padding-left: 10px;
  min-width: 82px;
  text-align: right;
}

.generate-loading.generate-more-btn span {
  margin-left: 0;
}

.generate-more-btn {
  cursor: pointer;
  background-color: #dbe3ea;
  padding-left: 10px;
  padding-right: 10px;
}

.generate-more-btn:hover {
  background-color: #e7eef4;

}

.generate-more-btn svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  margin-right: 7px;

  path {
    fill: $primary-color;
  }
}



@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

@media (max-height: 900px) {
  .chat-textarea-wrap {
    padding-bottom: 30px;
    box-shadow: 0 -12px 13px rgba(255, 255, 255, 0.75);
  }

}

@media (max-height: 760px) {
  .chat-textarea-wrap .send-btn {
    top: 0px;
  }

}

@media (max-width: 767px) {
  .chat-textarea-wrap {
    position: fixed;
    left: 0;
    right: 0;
    padding: 5px 10px;
    /* border-top: 1px solid #ddd; */
    box-shadow: 0 0 6px rgba(92, 107, 122, 0.7);
    background-color: #dce2e9;
  }

  .chat-textarea-form {
    padding: 15px 40px 15px 10px;
  }

  .chat-textarea-wrap .textarea-item {
    max-height: 80px;
    line-height: 20px;
  }

  .chat-textarea-wrap .send-btn {
    width: 24px;
    height: 24px;
    top: 12px;
  }

  .generate-loading {
    position: absolute;
    right: 0px;
    bottom: 83px;
    height: 30px;
    top: inherit;
    padding-left: 12px;
    padding-right: 3px;
    border-radius: 7px;
  }

  .generate-loading span {
    font-size: 11px;
  }

  .chat-forms-btns-wrap {
    flex-wrap: wrap;
  }

  .chat-select-box {
    width: 100%;
  }

  .chat-textarea-new-chat-wrap {
    margin-bottom: 5px;
  }

  .chat-select-box {
    padding: 4px 8px;
  }

  .new-chat-btn-text {
    display: none;
  }

  .cht-select-box-form {
    flex: 1;
  }

  .chat-select-box select.form-select {
    width: 100%;
  }

  .text-length-show {
    line-height: 20px;
  }
}



.generate-loading {
  border: 1px solid #1A73E8;
  border-radius: 50px;
  width: max-content;
  text-align: center;
  padding: 6px 15px;
  position: absolute;
  left: 0;
  top: -45px;
  color: rgba(26, 115, 232, 1);
  font-weight: 600;
  font-weight: 13px;

  &.generate-more-btn {
    background: #EFF6FF;
  }

  &.generate-more-seperate {
    background: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 7px;
  }

  &.generate-more-seperate-loading {
    border: 1px solid #D8D8D8;
    border-radius: 7px;
  }

  span {
    margin: 0;
  }
}

.textarea-form-wrap {
  flex: 1;
}

.upload-clip-btn {
  margin-right: 10px;
}

.info-text-chat-textarea {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  font-size: 13px;
  padding-top: 14px;
  color: #656b73;
  @media(max-width:575px) {
    padding-top: 9px; 
    font-size: 10px;
  }
  @media(max-height:800px) {
    padding-top: 7px; 
   }
}