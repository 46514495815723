#react-select-2-listbox {
    border: none !important;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 22%);
    padding: 0 10px;
}
.react-select-2-option__option {
    display: flex !important;
    align-items: center;
    border-bottom: 1px solid #d4d7da;
    background: none !important;
    padding-top: 10px;
    padding-bottom: 10px;
}
.react-select-2-option__menu-list div:last-child .react-select-2-option__option {
    border: none;
}
.react-select-2-option__option label {
    margin-left: 10px;
}

.react-select-2-option__option--is-selected label,
.react-select-2-option__option:hover label {
    color: $primary-color;
}
